import React, { useState, useEffect } from "react";

import axios from "axios";

import "./Teams.css";
import "./TeamMembers.css";
import { Link } from "react-router-dom";
import TopMembersData from "../../Data/BoradMembers";
import MiddleMembers from "../../Data/ManagmentMembers";
import Ambassadors from "../../Data/Ambassador";
import AboutFoundation from "../Foundations/AboutFoundation";

const TeamMembers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setdata] = useState([]);
  const [DataTwo, setDataTwo] = useState([]);

  useEffect(() => {
    // Fetch the First set of data for team title
    const apiUrl = `${process.env.REACT_APP_API_URL}api/allProffesionsdata`;
    axios
      .get(apiUrl)
      .then((result) => {
        setDataTwo(result.data);
      })
      .catch((error) => {
        console.error("Error fetching second data:", error);
      });

    // second Fetch Data
    const api = `${process.env.REACT_APP_API_URL}api/allourboardteamsdata`;
    axios
      .get(api)
      .then((result) => {
        setdata(
          result.data.map((item) => ({
            id: item.id,
            name: item.name,
            persontitle: item.persontitle,
            facebook: item.facebook,
            x: item.x,
            linkedin: item.linkedin,
            image: item.image,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="teams" id="services">
      <section className="team">
        {Array.isArray(DataTwo) &&
        DataTwo.length >= 1 &&
        DataTwo[0].id === 1 &&
        DataTwo[0].category !== "N" ? (
          <div className="team_title home__contents" key={DataTwo[0].id}>
            <h1 className="h1 tea">{DataTwo[0].category}</h1>
          </div>
        ) : (
          <div className="team_title home__contents">
            <h1 className="h1 tea">Meet Our Team</h1>
          </div>
        )}
        <div className="teamDetail">
          {Array.isArray(DataTwo) &&
          DataTwo.length >= 2 &&
          DataTwo[1].id === 2 &&
          DataTwo[1].category !== "N" ? (
            <div className="team__contents_Title">
              <h1 className="h1_team_title">{DataTwo[1].category}</h1>
            </div>
          ) : (
            <div className="team__contents_Title">
              <h1 className="h1_team_title">Executive Board members</h1>
            </div>
          )}

          <div className="Top">
            {data.length >= 1
              ? data.slice(0, 3).map((txt, i) => (
                  <div key={i}>
                    <article className="team__member" key={i}>
                      <div className="team__member-img">
                        <img
                          className="pics"
                          src={`${process.env.REACT_APP_API_URL}${txt.image}`}
                          alt={`${i}-img`}
                        />
                      </div>

                      <div className="team__member-info">
                        <h3 className="person_the_name person_name">
                          {txt.name}
                        </h3>
                        <h2 className="person_the_title">{txt.persontitle}</h2>

                        <div className="team__member-socials">
                          <Link to={txt.x}>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Svg/icons8-twitter-x-bg.svg"
                              }
                              alt="X"
                              className="socialSvg twitterSvg"
                            />
                          </Link>
                          <Link to={txt.facebook}>
                            <i className="uil-facebook"></i>
                          </Link>
                          <Link to={txt.linkedin}>
                            <i className="uil-linkedin"></i>
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                ))
              : TopMembersData.map((member, index) => (
                  <article className="team__member" key={index}>
                    <div className="team__member-img profile">
                      <img
                        className="pics"
                        src={process.env.PUBLIC_URL + member.image}
                        alt={`${member.name}-img`}
                      />
                    </div>

                    <div className="team__member-info">
                      <h3 className="person_the_name">{member.name}</h3>
                      <h2 className="person_the_title">{member.title}</h2>

                      <div className="team__member-socials">
                        <Link to={member.twitter}>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Svg/icons8-twitter-x-bg.svg"
                            }
                            alt="X"
                            className="socialSvg twitterSvg"
                          />
                        </Link>
                        <Link to={member.facebook}>
                          <i className="uil-facebook"></i>
                        </Link>
                        <Link to={member.linkedin}>
                          <i className="uil-linkedin"></i>
                        </Link>
                      </div>
                    </div>
                  </article>
                ))}
          </div>

          {Array.isArray(DataTwo) &&
          DataTwo.length >= 3 &&
          DataTwo[2].id === 3 &&
          DataTwo[2].category !== "N" ? (
            <div className="team__contents_Title secondSection">
              <h1 className="h1_team_title">{DataTwo[2].category}</h1>
            </div>
          ) : (
            <div className="team__contents_Title secondSection">
              <h1 className="h1_team_title">Executive Management Members</h1>
            </div>
          )}

          <div className="middle">
            {data.length >= 4
              ? data.slice(3, 6).map((txt, i) => (
                  <div key={i}>
                    <article className="team__member" key={i}>
                      <div className="team__member-img">
                        <img
                          className="pics"
                          src={`${process.env.REACT_APP_API_URL}/${txt.image}`}
                          alt={`${i}-img`}
                        />
                      </div>

                      <div className="team__member-info">
                        <h3 className="person_the_name person_name">
                          {txt.name}
                        </h3>
                        <h2 className="person_the_title">{txt.persontitle}</h2>

                        <div className="team__member-socials">
                          <Link to={txt.x}>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Svg/icons8-twitter-x-bg.svg"
                              }
                              alt="X"
                              className="socialSvg twitterSvg"
                            />
                          </Link>
                          <Link to={txt.facebook}>
                            <i className="uil-facebook"></i>
                          </Link>
                          <Link to={txt.linkedin}>
                            <i className="uil-linkedin"></i>
                          </Link>
                        </div>
                      </div>
                    </article>
                  </div>
                ))
              : MiddleMembers.map((member, index) => (
                  <article className="team__member" key={index}>
                    <div className="team__member-img profile">
                      <img
                        className="pics"
                        src={process.env.PUBLIC_URL + member.image}
                        alt={`${member.name}-img`}
                      />
                    </div>

                    <div className="team__member-info">
                      <h3 className="person_the_name">{member.name}</h3>
                      <h2 className="person_the_title">{member.title}</h2>
                      <div className="team__member-socials">
                        <Link to={member.twitter}>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Svg/icons8-twitter-x-bg.svg"
                            }
                            alt="X"
                            className="socialSvg twitterSvg"
                          />
                        </Link>
                        <Link to={member.facebook}>
                          <i className="uil-facebook"></i>
                        </Link>
                        <Link to={member.linkedin}>
                          <i className="uil-linkedin"></i>
                        </Link>
                      </div>
                    </div>
                  </article>
                ))}
          </div>

          <div className="Ambassador">
            {Array.isArray(DataTwo) &&
            DataTwo.length >= 4 &&
            DataTwo[3].id === 4 &&
            DataTwo[3].category !== "N" ? (
              <div className="ambassadorTitle ambassadorTitleCenter">
                <h1 className="h1_team_title">{DataTwo[3].category}</h1>
              </div>
            ) : (
              <div className="ambassadorTitle ambassadorTitleCenter">
                <h1 className="h1_team_title ">Our Good Will Ambassador</h1>
              </div>
            )}

            <div className="ambassadors">
              {data.length >= 7
                ? data.slice(6, 7).map((txt, i) => (
                    <div key={i}>
                      <article className="team__member" key={i}>
                        <div className="team__member-img">
                          <img
                            className="pics"
                            src={`${process.env.REACT_APP_API_URL}/${txt.image}`}
                            alt={`${i}-img`}
                          />
                        </div>

                        <div className="team__member-info">
                          <h3 className="person_the_name person_name">
                            {txt.name}
                          </h3>
                          <h2 className="person_the_title">
                            {txt.persontitle}
                          </h2>

                          <div className="team__member-socials">
                            <Link to={txt.x}>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Svg/icons8-twitter-x-bg.svg"
                                }
                                alt="X"
                                className="socialSvg twitterSvg"
                              />
                            </Link>
                            <Link to={txt.facebook}>
                              <i className="uil-facebook"></i>
                            </Link>
                            <Link to={txt.linkedin}>
                              <i className="uil-linkedin"></i>
                            </Link>
                          </div>
                        </div>
                      </article>
                    </div>
                  ))
                : Ambassadors.map((member, index) => (
                    <article className="team__member" key={index}>
                      <div className="team__member-img profile">
                        <img
                          className="pics"
                          src={process.env.PUBLIC_URL + member.image}
                          alt={`${member.name}-img`}
                        />
                      </div>
                      <div className="team__member-info">
                        <h3 className="person_the_name">{member.name}</h3>
                        <h2 className="person_the_title">{member.title}</h2>
                        <div className="team__member-socials">
                          <Link to={member.twitter}>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Svg/icons8-twitter-x-bg.svg"
                              }
                              alt="X"
                              className="socialSvg twitterSvg"
                            />
                          </Link>
                          <Link to={member.facebook}>
                            <i className="uil-facebook"></i>
                          </Link>
                          <Link to={member.linkedin}>
                            <i className="uil-linkedin"></i>
                          </Link>
                        </div>
                      </div>
                    </article>
                  ))}
            </div>
          </div>
        </div>
      </section>
      <AboutFoundation></AboutFoundation>
    </div>
  );
};

export default TeamMembers;
