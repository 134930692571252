import React, { useState, useEffect } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";

const UpdateBoardTeams = () => {
  const [ids, setIds] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [name, setName] = useState("");
  const [persontitle, setPersonTitle] = useState("");
  const [facebook, setFacebook] = useState("");
  const [x, setX] = useState("");
  const [linkedln, setlinkedln] = useState("");
  const [image, setImage] = useState(null);
  const { messages, addMessage, removeMessage } = useMessage();

  useEffect(() => {
    const fetchIds = async () => {
      try {
        const response = await api.get("api/allourboardteamsdata");
        setIds(response.data);
      } catch (error) {
        addMessage(`Failed to fetch records. ${error}`, "error");
      }
    };

    fetchIds();
  }, [addMessage]);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name.length >= 100 || persontitle.length >= 100) {
      return addMessage(
        "name and Person Title must be under 200 characters",
        "error"
      );
    }
    if (facebook.length >= 100 || x.length >= 100 || linkedln.length >= 100) {
      return addMessage("Team contacts must be under 100 characters", "error");
    }
    try {
      const formData = new FormData();
      formData.append("name", name || "");
      formData.append("persontitle", persontitle || "");
      formData.append("facebook", facebook || "");
      formData.append("x", x || "");
      formData.append("linkedln", linkedln || "");
      formData.append("image", image || null);

      if (name === "" || persontitle === "" || image === null) {
        addMessage(`Empty User Data`, "error");
      } else {
        await api.post(
          `api/updateallourboardteamsdata/${selectedId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        addMessage("User Record updated successfully", "success");
      }
    } catch (error) {
      addMessage(`Failed to update User Record. ${error}`, "error");
    }
  };

  return (
    <div className="Admin-form " style={{ marginBottom: "30%" }}>
      <h2 className="tit__1">Update Member Contact Info</h2>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon">Note</strong> If you leave this title
        Field Empty,{" "}
        <u className="black">the default value will be displayed.</u>
      </p>
      <br />
      <form onSubmit={handleSubmit}>
        <div className="flex">
          {/* <label htmlFor="id"> */}
          <label>
            <select
              className="input"
              value={selectedId}
              onChange={(e) => setSelectedId(e.target.value)}
            >
              <option value="">Select Team Record</option>
              {ids.map((record) =>
                record.name === "N" ? (
                  <option key={record.id} value={record.id}>
                    {record.id}
                  </option>
                ) : (
                  <option key={record.id} value={record.id}>
                    {record.name}
                  </option>
                )
              )}
            </select>
          </label>
        </div>
        <br />

        <div className="name_and_title">
          <div className="flex">
            <label>
              <input
                className="input"
                type="text"
                placeholder="Name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete="off"
              />
            </label>
          </div>

          <div className="flex">
            <label>
              <input
                className="input"
                type="text"
                placeholder="Title"
                id="persontitle"
                value={persontitle}
                onChange={(e) => setPersonTitle(e.target.value)}
                autoComplete="off"
              />
            </label>
          </div>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="Facebook Link"
              id="facebook"
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
              autoComplete="off"
            />
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="X Link"
              id="x"
              value={x}
              onChange={(e) => setX(e.target.value)}
              autoComplete="off"
            />
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="linkedln Link"
              id="linkedln"
              value={linkedln}
              onChange={(e) => setlinkedln(e.target.value)}
              autoComplete="off"
            />
          </label>
        </div>
        <br />

        <div className="flex">
          {" "}
          <label>
            <input
              className="input"
              type="file"
              name="image"
              onChange={handleFileChange}
            />
          </label>
        </div>
        <br />

        <div style={{ display: "grid", justifySelf: "end" }}>
          <button className="bookmarkBtn buttonUp">
            <span className="IconContainer">
              <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
              </svg>
            </span>
            <p className="text">Upload</p>
          </button>
        </div>
      </form>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </div>
  );
};

export default UpdateBoardTeams;
