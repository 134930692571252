import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import axios from "axios";

import { useAuth } from "../global/Auth/AuthProvider";

import "./Login.css";

function Login() {
  const navigate = useNavigate();
  const { loginAction } = useAuth();

  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleInput = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/Adminlogin`,
        formData,
        { withCredentials: true }
      );

      if (response.data.success && response.data.data.accessToken) {
        // creating token from response.data
        const newAccessToken = response.data.data.accessToken;
        // storing in to local storage
        sessionStorage.setItem("accessToken", newAccessToken);

        await loginAction(newAccessToken, setError); // Update user state

        // Navigate to home page after login
        // console.log("Navigating to home page...");
        navigate("/AdminPage"); // Navigate after state update
        // console.log("Navigated");
      } else {
        console.log("Login failed. Please try again.");
        throw new Error("Login failed.");
      }
    } catch (error) {
      console.error(
        "Login error:",
        error.response?.data?.error || error.message
      );
    }
  };

  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/users/count`;
        const response = await fetch(apiUrl);
        if (response.status === 200) {
          const data = await response.json();
          setTotalUsers(data.users);
        } else {
          setTotalUsers(3); // Set totalUsers to a value greater than 2
        }
      } catch (error) {
        console.error("Error fetching total users:", error);
      }
    };
    fetchTotalUsers();
  }, []);

  return (
    <div className="container LoginContainer">
      <div className="Logincard">
        <img
          src={process.env.PUBLIC_URL + "/assets/logo/sam-logo-12.png"}
          className="LoginLogo"
          alt="Samrawit Logo"
        />

        {error && <div className="error-message">{error}</div>}

        <h1 className="login">Log in</h1>
        <form onSubmit={handleSubmitEvent} className="grid container loginForm">
          <div className="LogininputBox">
            <input
              type="text"
              name="username"
              required="required"
              autoComplete="off"
              onChange={handleInput}
            />
            <span className="Loginuser">Username</span>
          </div>
          <div className="LogininputBox">
            <input
              type="password"
              name="password"
              required="required"
              autoComplete="off"
              aria-describedby="user-password"
              aria-invalid="false"
              onChange={handleInput}
            />
            <span>Password</span>
          </div>

          <button type="submit" className="Loginenter center">
            Enter
          </button>
          <div className="creatandReset">
            {totalUsers !== null && totalUsers <= 2 && (
              <div className="creatandReset">
                <p className="signup-link">
                  No account ?{" "}
                  <NavLink to="/CreateSmAccount-Management">Sign up</NavLink>
                </p>
              </div>
            )}
            <div>
              <p className="signup-link">
                <NavLink to="/forget-pass">Forgot password ? </NavLink>
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
