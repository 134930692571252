import React, { useEffect, useState } from "react";

import axios from "axios";

import Founders from "../home/Founders";
import "./Introduction.css";
import Social from "../home/Social";
import Data from "../home/Data";
import Description from "../Description/Description";

function Introduction() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/homepageaboutSM`;
    axios
      .get(apiUrl)
      .then((result) => {
        setdata(
          result.data.map((item) => ({
            id: item.id,
            content: item.content,
          }))
        );
      })

      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div>
      <div className="HomePagefirstSection">
        <div className="Home-social-div">
          <Social />
        </div>

        <div className="homeDescription ">
          <Description />
        </div>

        {/* <div className='home-Data container '> */}
        <div className="container">
          <Data />
        </div>
      </div>

      <div className="home__contents homepageDis" id="foundation">
        <h1 className="h1">Samrawit Foundation</h1>
      </div>
      <div className="introduction_section">
        {Array.isArray(data) && data.length > 0 && data[0].content === "N" ? (
          <p>
            "She was 9 years old when she was passed away on 12.10.13 (E.C) or
            19/06/2021 (G.C) due to a sudden accident. Due to this, her parents
            determined to ascertain some kind of organization by securing the
            start-up fund, establishing a proper coordination office, hiring the
            necessary staffing intended to support marginalized and destitute
            children, mothers and elder people in her name."
          </p>
        ) : data.length > 0 ? (
          data.map((txt, i) => <p key={i}>{txt.content}</p>)
        ) : (
          <p>
            "She was 9 years old when she was passed away on 12.10.13 (E.C) or
            19/06/2021 (G.C) due to a sudden accident. Due to this, her parents
            determined to ascertain some kind of organization by securing the
            start-up fund, establishing a proper coordination office, hiring the
            necessary staffing intended to support marginalized and destitute
            children, mothers and elder people in her name."
          </p>
        )}
      </div>

      <Founders />
    </div>
  );
}

export default Introduction;
