import React from "react";

import "./Foundation.css";
import Objectives from "./Objectives";

function Foundation() {
  return (
    <div className="Foundation_section container">
      <div className="Foundation_section_two">
        <Objectives />
      </div>
    </div>
  );
}

export default Foundation;
