import React, { useEffect, useState } from "react";

import axios from "axios";

import galleryImagesSlider from "../../Data/ImageSlider";

const Aboutusgallery = () => {
  const [slide, setSlide] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((prevSlide) => (prevSlide + 1) % galleryImagesSlider.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/getAboutusgallery`;
    axios
      .get(apiUrl)
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        console.error("Error fetching images:", err);
      });
  }, []);

  // Create a copy of the images array before sorting to avoid mutating the state
  const sortedImages = [...images].sort((a, b) => b.id - a.id);

  return (
    <>
      {sortedImages.length > 2 ? (
        <div className="img-slider-container">
          <div className="img-slider carousel">
            {sortedImages.map((image, i) => (
              <img
                key={image.id}
                src={`${process.env.REACT_APP_API_URL}${image.image}`}
                alt={`Slide ${i + 1}`}
                className={slide === i ? "slide" : "slide slide-hidden"}
              />
            ))}
            <span className="indicators">
              {sortedImages.map((item, i) => (
                <button
                  key={i}
                  className={
                    slide === i ? "indicator" : "indicator indicator-inactive"
                  }
                  onClick={() => setSlide(i)}
                ></button>
              ))}
            </span>
          </div>
        </div>
      ) : (
        <div className="img-slider-container">
          <div className="img-slider carousel">
            {galleryImagesSlider.map((item, i) => (
              <img
                key={i}
                src={item.img}
                alt={`Slide ${i + 1}`}
                className={slide === i ? "slide" : "slide slide-hidden"}
              />
            ))}
            <span className="indicators">
              {galleryImagesSlider.map((item, i) => (
                <button
                  key={i}
                  className={
                    slide === i ? "indicator" : "indicator indicator-inactive"
                  }
                  onClick={() => setSlide(i)}
                ></button>
              ))}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Aboutusgallery;
