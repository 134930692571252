import React from "react";

import "./Donate.css";

const BankCards = () => {
  return (
    <div className="ContactUsBody ContactusDonate">
      <div className="">
        <div className="modalz">
          <div className="infos">
            <ul className="account">
              <li className="AllAccounts">
                <h3>
                  <i className="uil uil-university">
                    &#160;
                    <strong className="banks">
                      Commercial Bank Of Ethiopia
                    </strong>
                  </i>
                </h3>
                <br></br>
                <div className="banksAccounts">
                  <p>Samrawit Foundation Account No:</p>
                  <br />
                  <p>1000551542778</p>
                </div>
              </li>
              <hr />
              <br />

              <li className="AllAccounts">
                <h3>
                  <i className="uil uil-university">
                    &#160;<strong className="banks">Dashen Bank</strong>
                  </i>
                </h3>
                <br></br>
                <div className="banksAccounts">
                  <p>Samrawit Foundation Account No:</p>
                  <br />
                  <p>0534334457011</p>
                </div>
              </li>
              <hr />
              <br />

              <li className="AllAccounts">
                <h3>
                  <i className="uil uil-university">
                    &#160;<strong className="banks">Telebirr</strong>
                  </i>
                </h3>
                <br></br>
              </li>
              <div className="banksAccounts">
                <li>&#160; 1. Open your telebirr superapp</li>

                <li>&#160; 2. Click ‘’More’’</li>

                <li>&#160; 3. Click ‘’Fundraising’’</li>

                <li>
                  &#160; 4. From list of Organizations choose ‘’Samrawit
                  Foundation’’
                </li>

                <li>
                  &#160; 5. ‘’Subscribe’’ the amount as you can to periodically
                  donate
                </li>
              </div>

              <br />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankCards;
