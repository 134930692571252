import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { jwtDecode } from "jwt-decode";
import { api } from "./api";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const decodeToken = (token) => {
    try {
      const decoded = jwtDecode(token);

      return {
        email: decoded.email,
        exp: decoded.exp,
      };
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  // Login
  const loginAction = async (token) => {
    const decoded = decodeToken(token);

    if (decoded) {
      setUser({ email: decoded.email });
      setAccessToken(token);
    }
  };

  // Logout
  const logoutAction = useCallback(async () => {
    try {
      await api.post(
        "api/logout",
        {}, // No body needed
        { withCredentials: true } // Include cookies
      );
    } catch (error) {
      console.error("Logout error:", error?.response?.data || error.message);
    } finally {
      setUser(null);
      setAccessToken(null);
      sessionStorage.clear();
      window.location.href = "/#/login"; //  redirect login
    }
  }, []);

  const initializeAuth = useCallback(() => {
    const token = sessionStorage.getItem("accessToken");
    if (token) {
      const decoded = decodeToken(token);
      if (decoded && decoded.exp * 1000 > Date.now()) {
        setUser({ userId: decoded.userId, email: decoded.email });
        setAccessToken(token);
        // console.log("User authenticated____________", token);
      } else {
        console.log("Token expired, logging out...");
        logoutAction();
      }
    }
    setLoading(false);
  }, [logoutAction]);

  // refresh the access token using the refresh token incase of expiration(access token)
  const refreshAccessToken = useCallback(async () => {
    try {
      const response = await api.post("api/accessToken");

      // get the new accesstoken from the response
      const { accessToken: newAccessToken } = response.data;

      // save the accessToken
      sessionStorage.setItem("accessToken", newAccessToken);
      // set it to the react state also
      setAccessToken(newAccessToken);

      // decode the access token to get the user email and id
      const decoded = decodeToken(newAccessToken);
      setUser({ userId: decoded.userId, email: decoded.email });
    } catch (error) {
      console.error("Token refresh error:", error);
      logoutAction();
    }
  }, [logoutAction]);

  // initializeAuth
  useEffect(() => {
    initializeAuth();
  }, [initializeAuth]);

  // proactive token refresh
  useEffect(() => {
    if (accessToken) {
      const decoded = decodeToken(accessToken);
      const expiresIn = decoded.exp * 1000 - Date.now();

      // Set a timer to refresh the token slightly before it expires
      const refreshTimeout = setTimeout(() => {
        refreshAccessToken();
      }, expiresIn - 5000); // Refresh 5 seconds before expiry

      return () => clearTimeout(refreshTimeout); // Cleanup on unmount
    }
  }, [accessToken, refreshAccessToken]);

  return (
    <AuthContext.Provider
      value={{
        user,
        accessToken,
        isAuthenticated: !!user,
        loading,
        loginAction,
        logoutAction,
        refreshAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
