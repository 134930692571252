import React, { useEffect, useState } from "react";

import axios from "axios";

import "./data.css";

function Data() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/homepicturelandingtxt`;
    axios
      .get(apiUrl)

      .then((result) => {
        if (Array.isArray(result.data) && result.data.length > 0) {
          const secondRecord = result.data[1];
          setdata([{ id: secondRecord.id, content: secondRecord.content }]);
        } else {
          setdata([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="home__data container grid ">
      <div className="home__description">
        <div className="home_detail dataComponenet ">
          {Array.isArray(data) && data.length > 0 && data[0].content === "N" ? (
            <p className="">
              "Empowering hope is at the core of our mission, driving us to
              create positive change in the lives of individuals and
              communities. Through collaborative efforts and a shared
              commitment, we join hands to make a meaningful impact. Together,
              we strive to empower, inspire, and transform lives, fostering a
              brighter and more promising future for all."
            </p>
          ) : data.length > 0 ? (
            data.map((txt, i) => <p key={i}>{txt.content}</p>)
          ) : (
            <p className="">
              "Empowering hope is at the core of our mission, driving us to
              create positive change in the lives of individuals and
              communities. Through collaborative efforts and a shared
              commitment, we join hands to make a meaningful impact. Together,
              we strive to empower, inspire, and transform lives, fostering a
              brighter and more promising future for all."
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Data;
