import React, { useState } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";

const FooterContentForm = () => {
  const [subtitle, setSubtitle] = useState("");
  const [mobileOne, setMobileOne] = useState("");
  const [mobileTwo, setMobileTwo] = useState("");
  const [mobileThree, setMobileThree] = useState("");
  const [emailOne, setEmailOne] = useState("");
  const [emailTwo, setEmailTwo] = useState("");
  const [emailThree, setEmailThree] = useState("");
  const { messages, addMessage, removeMessage } = useMessage();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      subtitle.length >= 300 ||
      mobileOne.length >= 300 ||
      mobileTwo.length >= 300 ||
      mobileThree.length >= 300 ||
      emailOne.length >= 300 ||
      emailTwo.length >= 300 ||
      emailThree.length >= 300
    ) {
      return addMessage("filds must be under 100 characters", "error");
    }

    try {
      await api.post("api/updatefooterContent", {
        subtitle,
        mobileone: mobileOne,
        mobiletwo: mobileTwo,
        mobilethree: mobileThree,
        emailone: emailOne,
        emailtwo: emailTwo,
        emailthree: emailThree,
      });
      addMessage("Footer content updated successfully", "success");
    } catch (error) {
      addMessage(`Failed to update footer content: ${error}`, "error");
    }
  };

  return (
    <div className="Admin-form" style={{ height: "fit-content" }}>
      <form onSubmit={handleSubmit}>
        <h2 className="tit__1">Update Footer Content Addresses</h2>
        <p className="Expensivemessage">
          <strong className="ExpensiveIcon">Note</strong> If you leave this
          Field Empty,{" "}
          <u className="black">the default value will be displayed.</u>
        </p>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              id="subtitle"
              placeholder="sub-title "
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
            />{" "}
          </label>
        </div>
        <br />

        <div className="threeLinks">
          <div className="threeNumbers">
            <div className="flex">
              <label>
                <input
                  className="input"
                  type="text"
                  placeholder="First Mobile number "
                  id="mobileOne"
                  value={mobileOne}
                  onChange={(e) => setMobileOne(e.target.value)}
                />
              </label>
            </div>
            <div className="flex">
              <label>
                <input
                  className="input"
                  type="text"
                  placeholder="Second Mobile number "
                  id="mobileTwo"
                  value={mobileTwo}
                  onChange={(e) => setMobileTwo(e.target.value)}
                />
              </label>
            </div>
            <div className="flex">
              <label>
                <input
                  className="input"
                  type="text"
                  placeholder="Third Mobile number "
                  id="mobileThree"
                  value={mobileThree}
                  onChange={(e) => setMobileThree(e.target.value)}
                />
              </label>
            </div>
          </div>

          <div className="threeEmail">
            <div className="flex">
              <label>
                <input
                  className="input"
                  type="text"
                  placeholder="Email Adress one "
                  id="emailOne"
                  value={emailOne}
                  onChange={(e) => setEmailOne(e.target.value)}
                />
              </label>
            </div>
            <div className="flex">
              <label>
                <input
                  className="input"
                  type="text"
                  placeholder="Email Adress two "
                  id="emailTwo"
                  value={emailTwo}
                  onChange={(e) => setEmailTwo(e.target.value)}
                />
              </label>
            </div>
            <div className="flex">
              <label>
                <input
                  className="input"
                  type="text"
                  placeholder="Email Adress three "
                  id="emailThree"
                  value={emailThree}
                  onChange={(e) => setEmailThree(e.target.value)}
                />
              </label>
            </div>
          </div>
        </div>
        <br />

        <div style={{ display: "grid", justifySelf: "end" }}>
          <button className="bookmarkBtn buttonUp">
            <span className="IconContainer">
              <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
              </svg>
            </span>
            <p className="text">Upload</p>
          </button>
        </div>
      </form>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </div>
  );
};

export default FooterContentForm;
