import React from "react";
import "./HelperCard.css";

const HelperCard = ({ title, description, others }) => {
  return (
    <div className="user_account HelperCard-cookie-card">
      <span className="HelperCard-title">{title}</span>
      <p className="HelperCard-description">{description}</p>
      <div className="HelperCard-actions">{others}</div>
    </div>
  );
};

export default HelperCard;
