import React, { useState } from "react";

import AdminAboutUsPageComponent from "./AdminAboutUsPage";
import WhoweareComponent from "./Whoweare";
import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";
import AboutusgalleryImageSlider from "./AboutusgalleryUploadform";
import AboutusgalleryComponent from "./Aboutusgallery";
import AboutusyoutubeComponent from "./Aboutusyoutube";
import ShowYoutube from "./ShowYoutube";

const AdminAboutPage = () => {
  // State to track which form is selected
  const [selectedForm, setSelectedForm] = useState("AdminAboutUsPageComponent");

  // Handler for switching between forms
  const handleFormChange = (form) => {
    setSelectedForm(form);
  };
  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="userChoice">
          <div className="formNav">
            {/* Buttons for selecting the form */}
            <button
              className={
                selectedForm === "AdminAboutUsPageComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("AdminAboutUsPageComponent")}
            >
              About Us Header
            </button>
            <button
              className={selectedForm === "WhoweareComponent" ? "active" : ""}
              onClick={() => handleFormChange("WhoweareComponent")}
            >
              Who we are cards
            </button>
            <button
              className={
                selectedForm === "AboutusyoutubeComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("AboutusyoutubeComponent")}
            >
              About Us Youtube Links
            </button>
            <button
              className={
                selectedForm === "AboutusgalleryImageSlider" ? "active" : ""
              }
              onClick={() => handleFormChange("AboutusgalleryImageSlider")}
            >
              About Us Image Slider
            </button>
          </div>

          <div className="adminpageS container">
            {selectedForm === "AdminAboutUsPageComponent" && (
              <AdminAboutUsPageComponent />
            )}
            {selectedForm === "WhoweareComponent" && <WhoweareComponent />}

            {selectedForm === "AboutusyoutubeComponent" && (
              <div className="grid-horizontal">
                <AboutusyoutubeComponent />

                <div className="gallaryComponent">
                  <h3 className="oneLineTitle">Youtube links</h3>

                  <ShowYoutube />
                </div>
              </div>
            )}

            {selectedForm === "AboutusgalleryImageSlider" && (
              <div className="grid-horizontal">
                <AboutusgalleryImageSlider />

                <div className="gallaryComponent">
                  <h3 className="oneLineTitle">About Us Images (Slider)</h3>
                  <AboutusgalleryComponent />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default AdminAboutPage;
