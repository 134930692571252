import React, { useEffect, useState } from "react";

import axios from "axios";

import Header from "../components/header/Header";
import Home from "../components/home/Home";
import About from "../components/About/About";
import Foundation from "../components/Foundations/Foundation";
import Services from "../components/Services/Services";
import Contact from "../components/contact/Contact";
import Map from "../components/map/Map";
import Teams from "../components/teams/Teams";
import Introduction from "../components/introduction/Introduction";
import Footer from "../components/footer/Footer";
import Slider from "../components/ImageSlider/Slider";

const Samrawit = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/LandingPagePicture`;
        const response = await axios.get(apiUrl);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {data.length > 0 && data[0].image !== "N" ? (
        <div className="landing-Page">
          <Header />
          <div className="FoundationHeaderback">
            <img
              className="FullWindowImage"
              src={`${process.env.REACT_APP_API_URL}${data[0].image}`}
              alt="FoundationLanding-page"
            />
          </div>
          <div className="LandingPage-desc landingtwocontent">
            <Home />
          </div>
        </div>
      ) : (
        <>
          <div className="back">
            <Header />
            <div className="backHome ">
              <Home />
            </div>
          </div>
        </>
      )}

      <main className="container">
        <Introduction />
        <Foundation />
        <About />
        <Services />
        <Teams />
        <Contact />
        <Map />

        <Slider />
      </main>
      <div className="footerDIV">
        <Footer />
      </div>
    </>
  );
};

export default Samrawit;
