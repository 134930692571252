import React, { useState, useEffect } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";
import HelperCard from "../HelperCard/HelperCard";

const CreateCorevalues = () => {
  const { messages, addMessage, removeMessage } = useMessage();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [existingRecords, setExistingRecords] = useState(0);

  useEffect(() => {
    api
      .get("api/ourcorevalues")
      .then((result) => {
        setExistingRecords(result.data.length);
      })
      .catch((error) => {
        console.error("Error fetching existing records:", error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (existingRecords >= 3) {
      addMessage("Only 3 records allowed. Cannot create more.", "error");
      return;
    }

    try {
      await api.post("api/createOurcorevalues", {
        title: title,
        content: content,
      });
      addMessage("Record created successfully", "success");
    } catch (error) {
      console.error("Error creating record:", error);
      addMessage("An error occurred. Please try again.", "error");
    }
  };

  return (
    <>
      {existingRecords >= 3 ? (
        <HelperCard
          title="Core Values Content"
          description="You have already created three Core Values Contents. While we cannot add additional Contents, you are welcome to update the existing at the next tab here."
          others={
            <button className="HelperCard-accept">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-arrow-big-right-dash"
              >
                <path d="M5 9v6" />
                <path d="M9 9h3V5l7 7-7 7v-4H9V9z" />
              </svg>
            </button>
          }
        />
      ) : (
        <form className="Admin-form" onSubmit={handleSubmit}>
          <h2 className="tit__1">Create Core Values Content</h2>

          <p className="Expensivemessage">
            <strong className="ExpensiveIcon">Note</strong> You can only create{" "}
            <u className="black"> three</u> records
          </p>
          <br />
          <div className="Adminflex">
            <label>
              {" "}
              <input
                className="input"
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />{" "}
            </label>
          </div>
          <div className="flex">
            <div className="Admininput-wrapper">
              <textarea
                placeholder="Text"
                name="text"
                className="Admininput"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
            </div>
          </div>
          <button className="bookmarkBtn">
            <span className="IconContainer">
              <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
              </svg>
            </span>
            <p className="text">Submit</p>
          </button>
          <MessageComponent messages={messages} removeMessage={removeMessage} />
        </form>
      )}
    </>
  );
};

export default CreateCorevalues;
