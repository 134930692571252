import React, { useState, useEffect } from "react";

import axios from "axios";

import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  const email1 = "info@samrawitfoundation.org.et";
  const email2 = "contact@samrawitfoundation.org.et";
  const email3 = "tageln@samrawitfoundation.org.et";

  const [phoneNumbersVisible, setPhoneNumbersVisible] = useState(false);
  const [currentPhoneNumberIndex, setCurrentPhoneNumberIndex] = useState(0);

  // Define the phone numbers array here
  const phoneNumbers = [
    "+251 911 820 607",
    "+251 912 202 838",
    "+251 900 158 320",
  ];

  const handleCallClick = () => {
    setPhoneNumbersVisible(!phoneNumbersVisible);

    if (!phoneNumbersVisible) {
      const currentPhoneNumber = phoneNumbers[currentPhoneNumberIndex];
      window.location.href = `tel:${currentPhoneNumber}`;

      const nextIndex = (currentPhoneNumberIndex + 1) % phoneNumbers.length;
      setCurrentPhoneNumberIndex(nextIndex);
    }
  };

  const [data, setdata] = useState([]);

  useEffect(() => {
    fatchData();
  }, []);

  const fatchData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/footerContent`;
      const result = await axios(apiUrl);
      setdata(result.data);
    } catch (err) {
      console.log("something wrong");
    }
  };

  // // Links
  const [links, setlinks] = useState([]);

  useEffect(() => {
    fatchlinks();
  }, []);

  const fatchlinks = async () => {
    try {
      const api = `${process.env.REACT_APP_API_URL}api/Foundationlinks`;
      const result = await axios(api);
      setlinks(result.data);
    } catch (err) {
      console.log("something wrong");
    }
  };

  return (
    <footer className="footer ">
      <div className="footer__container container grid">
        <div className="footer_title_top">
          {Array.isArray(data) &&
          data.length > 0 &&
          data[0].subtitle === "N" ? (
            <div className="footer_title_title">
              <h1 className="footer__title">Samrawit Foundation</h1>
              <h5 className="footer__title_sub">
                “የተቸገሩትን ለመርዳት እስኪተርፈኝ አልጠብቅም”
              </h5>
            </div>
          ) : data.length > 0 ? (
            data.map((txt, i) => (
              <div className="footer_title_title" key={i}>
                <h1 className="footer__title">Samrawit Foundation</h1>
                <h5 className="footer__title_sub">{txt.subtitle}</h5>
              </div>
            ))
          ) : (
            <div className="footer_title_title">
              <h1 className="footer__title">Samrawit Foundation</h1>
              <h5 className="footer__title_sub">
                “የተቸገሩትን ለመርዳት እስኪተርፈኝ አልጠብቅም”
              </h5>
            </div>
          )}

          <div className="footer_section_Logo">
            <Link to="/" className="footerLogo">
              <img
                className="footer_img"
                src={process.env.PUBLIC_URL + "/assets/logo/Logo.png"}
                alt="Samrawit Logo"
              />
            </Link>
          </div>
        </div>

        <div className="footer_Top_Section container">
          <div className="phone_and_email">
            <div
              className="footer_section_mobile container"
              onClick={handleCallClick}
            >
              <h2 className="mobile_title">Mobile</h2>
              <br />
              <div className="mobs">
                {Array.isArray(data) &&
                data.length === 1 &&
                data[0].mobileone === "N" &&
                data[0].mobiletwo === "N" &&
                data[0].mobilethree === "N"
                  ? phoneNumbers.map((phoneNumber, index) => (
                      <div className="mobTel" key={index}>
                        <a href={`tel:${phoneNumber}`} className="phone">
                          {phoneNumber}
                        </a>
                        <br />
                      </div>
                    ))
                  : Array.isArray(data) &&
                    data.length >= 1 &&
                    data[0].mobileone !== "N" &&
                    data[0].mobiletwo !== "N" &&
                    data[0].mobilethree !== "N"
                  ? data.map((txt, i) => (
                      <div key={i}>
                        <div className="mobTel">
                          <a href={`tel:${txt.mobileone}`} className="phone">
                            {txt.mobileone}
                          </a>
                          <br />
                        </div>
                        <div className="mobTel">
                          <a href={`tel:${txt.mobiletwo}`} className="phone">
                            {txt.mobiletwo}
                          </a>
                          <br />
                        </div>
                        <div className="mobTel">
                          <a href={`tel:${txt.mobilethree}`} className="phone">
                            {txt.mobilethree}
                          </a>
                          <br />
                        </div>
                      </div>
                    ))
                  : phoneNumbers.map((phoneNumber, index) => (
                      <div className="mobTel" key={index}>
                        <a href={`tel:${phoneNumber}`} className="phone">
                          {phoneNumber}
                        </a>
                        <br />
                      </div>
                    ))}
              </div>
            </div>

            <div className="eml container">
              <h2 className="email">Email</h2>
              <br />

              {Array.isArray(data) &&
              data.length === 1 &&
              data[0].emailone === "N" &&
              data[0].emailtwo === "N" &&
              data[0].emailthree === "N" ? (
                <h4 className="mailto">
                  <a
                    href={`mailto:${email3}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {email3}
                  </a>
                  <br />
                  <a
                    href={`mailto:${email2}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {email2}
                  </a>
                  <br />
                  <a
                    href={`mailto:${email1}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {email1}
                  </a>
                </h4>
              ) : Array.isArray(data) &&
                data.length >= 1 &&
                data[0].emailone !== "N" &&
                data[0].emailtwo !== "N" &&
                data[0].emailthree !== "N" ? (
                data.map((txt, i) => (
                  <h4 className="mailto" key={i}>
                    <a
                      href={`mailto:${txt.emailone}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {txt.emailone}
                    </a>
                    <br />
                    <a
                      href={`mailto:${txt.emailtwo}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {txt.emailtwo}
                    </a>
                    <br />
                    <a
                      href={`mailto:${txt.emailthree}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {txt.emailthree}
                    </a>
                  </h4>
                ))
              ) : (
                <h4 className="mailto">
                  <a
                    href={`mailto:${email3}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {email3}
                  </a>
                  <br />
                  <a
                    href={`mailto:${email2}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {email2}
                  </a>
                  <br />
                  <a
                    href={`mailto:${email1}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {email1}
                  </a>
                </h4>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Socila medias */}
      <div className="footer__social container ">
        <h4 className="footerFollowUs">Follow us</h4>

        <div className="cardll">
          {Array.isArray(links) &&
          links.length > 0 &&
          links[0].youtube === "N" ? (
            <Link
              to="https://www.youtube.com/"
              className="socialContainer containerOne"
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/Svg/youtube.svg"}
                alt="Youtube"
                className="socialSvg instagramSvg"
              />
            </Link>
          ) : links.length > 0 ? (
            links.map((txt, i) => (
              <Link
                to={txt.youtube}
                className="socialContainer containerOne"
                key={i}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/Svg/youtube.svg"}
                  alt="Youtube"
                  className="socialSvg instagramSvg"
                />
              </Link>
            ))
          ) : (
            <Link
              to="https://www.youtube.com/"
              className="socialContainer containerOne"
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/Svg/youtube.svg"}
                alt="Youtube"
                className="socialSvg instagramSvg"
              />
            </Link>
          )}

          {Array.isArray(links) && links.length > 0 && links[0].x === "N" ? (
            <Link
              to="https://x.com/samrawitfound?t=x4Hd9Ab0xXlOAU2KxPLYOA&s=35"
              className="socialContainer containerTwo"
            >
              <img
                className="socialSvg twitterSvg"
                src={process.env.PUBLIC_URL + "/assets/Svg/icons8-twitterx.svg"}
                alt="X"
              ></img>
            </Link>
          ) : links.length > 0 ? (
            links.map((txt, i) => (
              <Link to={txt.x} className="socialContainer containerTwo" key={i}>
                <img
                  className="socialSvg twitterSvg"
                  src={
                    process.env.PUBLIC_URL + "/assets/Svg/icons8-twitterx.svg"
                  }
                  alt="X"
                />
              </Link>
            ))
          ) : (
            <Link
              to="https://x.com/samrawitfound?t=x4Hd9Ab0xXlOAU2KxPLYOA&s=35"
              className="socialContainer containerTwo"
            >
              <img
                className="socialSvg twitterSvg"
                src={process.env.PUBLIC_URL + "/assets/Svg/icons8-twitterx.svg"}
                alt="X"
              ></img>
            </Link>
          )}

          {Array.isArray(links) &&
          links.length > 0 &&
          links[0].telegram === "N" ? (
            <Link
              to="https://t.me/samrawitfoundation"
              className="socialContainer containerThree"
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/Svg/telegram.svg"}
                alt="Telegram"
                className="socialSvg linkdinSvg"
              />
            </Link>
          ) : links.length > 0 ? (
            links.map((txt, i) => (
              <Link
                to={txt.telegram}
                className="socialContainer containerThree"
                key={i}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/Svg/telegram.svg"}
                  alt="Telegram"
                  className="socialSvg linkdinSvg"
                />
              </Link>
            ))
          ) : (
            <Link
              to="https://t.me/samrawitfoundation"
              className="socialContainer containerThree"
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/Svg/telegram.svg"}
                alt="Telegram"
                className="socialSvg linkdinSvg"
              />
            </Link>
          )}

          {Array.isArray(links) &&
          links.length > 0 &&
          links[0].facebook === "N" ? (
            <Link
              to="https://www.facebook.com/profile.php?id=100090874201533&mibextid=ZbWKwL"
              className="socialContainer containerFour"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Svg/facebook-round-color-icon.svg"
                }
                alt="Facebook"
                className="socialSvg whatsappSvg"
              />
            </Link>
          ) : links.length > 0 ? (
            links.map((txt, i) => (
              <Link
                to={txt.facebook}
                className="socialContainer containerFour"
                key={i}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Svg/facebook-round-color-icon.svg"
                  }
                  alt="Facebook"
                  className="socialSvg whatsappSvg"
                />
              </Link>
            ))
          ) : (
            <Link
              to="https://www.facebook.com/profile.php?id=100090874201533&mibextid=ZbWKwL"
              className="socialContainer containerFour"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Svg/facebook-round-color-icon.svg"
                }
                alt="Facebook"
                className="socialSvg whatsappSvg"
              />
            </Link>
          )}

          {Array.isArray(links) &&
          links.length > 0 &&
          links[0].tiktok === "N" ? (
            <Link
              to="https://www.tiktok.com/@samrawitfoundation?_t=8hylLGsv1B0&_r=1"
              className="socialContainer containerfive"
            >
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/Svg/tiktok-color-icon.svg"
                }
                alt="TikTok"
                className="socialSvg whatsappSvg"
              />
            </Link>
          ) : links.length > 0 ? (
            links.map((txt, i) => (
              <Link
                to={txt.tiktok}
                className="socialContainer containerfive"
                key={i}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/Svg/tiktok-color-icon.svg"
                  }
                  alt="TikTok"
                  className="socialSvg whatsappSvg"
                />
              </Link>
            ))
          ) : (
            <Link
              to="https://www.tiktok.com/@samrawitfoundation?_t=8hylLGsv1B0&_r=1"
              className="socialContainer containerfive"
            >
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/Svg/tiktok-color-icon.svg"
                }
                alt="TikTok"
                className="socialSvg whatsappSvg"
              />
            </Link>
          )}

          {Array.isArray(links) &&
          links.length > 0 &&
          links[0].linkedln === "N" ? (
            <Link
              to="https://et.linkedin.com/in/tagel-nigatu-52b008278"
              className="socialContainer containersix"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Svg/linkedin-svgrepo-com.svg"
                }
                alt="linkdin"
                className="socialSvg whatsappSvg"
              />
            </Link>
          ) : links.length > 0 ? (
            links.map((txt, i) => (
              <Link
                to={txt.linkedln}
                className="socialContainer containerfive"
                key={i}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Svg/linkedin-svgrepo-com.svg"
                  }
                  alt="linkdin"
                  className="socialSvg whatsappSvg"
                />
              </Link>
            ))
          ) : (
            <Link
              to="https://et.linkedin.com/in/tagel-nigatu-52b008278"
              className="socialContainer containersix"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Svg/linkedin-svgrepo-com.svg"
                }
                alt="linkdin"
                className="socialSvg whatsappSvg"
              />
            </Link>
          )}
        </div>
      </div>

      <hr className="footerHR" />

      <div className="footerDetalis container">
        <span className="footer__copy">
          {" "}
          <strong>&#169; 2024 Samrawit Foundation. All rights reserved</strong>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
