import React, { useState } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";

const HomePictureTxtTwo = () => {
  const { messages, addMessage, removeMessage } = useMessage();

  const [content, setContent] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.put("api/updatehomepicturetxt/2", { content });
      addMessage("Record updated successfully", "success");
    } catch (error) {
      console.error("Error updating record:", error);
      addMessage("An error occurred. Please try again.", "error");
    }
  };

  return (
    <form className="Admin-form" onSubmit={handleSubmit}>
      <h2 className="tit__1">Update Paragraph two</h2>

      <p className="Expensivemessage">
        <strong className="ExpensiveIcon">Note</strong> If you leave this text
        area empty,{" "}
        <u className="black">the default value will be displayed.</u>
      </p>

      <br />

      <div className="flex">
        <div className="Admininput-wrapper">
          <textarea
            placeholder="Second Paragraph"
            name="text"
            className="Admininput"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
        </div>
      </div>

      <button className="bookmarkBtn">
        <span className="IconContainer">
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Submit</p>
      </button>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </form>
  );
};

export default HomePictureTxtTwo;
