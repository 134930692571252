import React, { useState, useEffect } from "react";

import axios from "axios";

const DonateHeader = () => {
  const [data, setdata] = useState([]);

  // get the data
  useEffect(() => {
    fatchData();
  }, []);

  // get the data
  const fatchData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/contactpicturetxt`;
      const result = await axios(apiUrl);
      setdata(result.data);
    } catch (err) {
      console.log("something wrong");
    }
  };
  return (
    <>
      <div className="teamsHome_section container">
        <div className="teamSection_title">
          {Array.isArray(data) && data.length > 0 && data[0].title === "N" ? (
            <h1 className="teamSection_title_h1">Join Us</h1>
          ) : data.length > 0 ? (
            data.map((txt, i) => (
              <h1 className="teamSection_title_h1" key={i}>
                {txt.title}
              </h1>
            ))
          ) : (
            <h1 className="teamSection_title_h1">Join Us</h1>
          )}
        </div>

        <div className="teams_moto">
          {Array.isArray(data) && data.length > 0 && data[0].content === "N" ? (
            <p className="foundaion_intro">
              Your gift, no matter how big or small can helps us make a
              meaningful impact in the lives of those who need it most
            </p>
          ) : data.length > 0 ? (
            data.map((txt, i) => (
              <p className="foundaion_intro" key={i}>
                {txt.content}
              </p>
            ))
          ) : (
            <p className="foundaion_intro">
              Your gift,no matter how big or small can helps us make a
              meaningful impact in the lives of those who need it most
            </p>
          )}
        </div>
      </div>
    </>
  );
};
export default DonateHeader;
