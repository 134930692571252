import React, { useState, useEffect } from "react";

import axios from "axios";

import "./Services.css";

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //For the title
  const [data, setdata] = useState([]);
  useEffect(() => {
    fatchData();
  }, []);

  // get the data
  const fatchData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/ourcampaignstitle`;
      const result = await axios.get(apiUrl);
      setdata(result.data);
    } catch (err) {
      console.log("something wrong");
    }
  };

  //For the 3 cards
  const [words, setWords] = useState([]);
  useEffect(() => {
    fetchcampaignData();
  }, []);

  const fetchcampaignData = async () => {
    try {
      const api = `${process.env.REACT_APP_API_URL}api/getcampaignsContent`;
      const response = await axios.get(api);
      setWords(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="services_section container" id="services">
      <div className="home__contents">
        <div>
          {Array.isArray(data) && data.length > 0 && data[0].title === "N" ? (
            <h1 className="h1 camp">OUR CAMPAIGNS</h1>
          ) : data.length > 0 ? (
            data.map((txt, i) => (
              <h1 className="h1 camp" key={i}>
                {txt.title}
              </h1>
            ))
          ) : (
            <h1 className="h1 camp">OUR CAMPAIGNS</h1>
          )}
        </div>
      </div>
      <div>
        {Array.isArray(words) &&
        words.length > 0 &&
        words[0].content === "N" ? (
          <div className="services_section_one">
            <div className="service_description_one">
              <h4 className="service_description_one_h4">
                Facilitate destitute, excluded and vulnerable children, mothers
                and elder people in Ethiopia to have the opportunity to improve
                their lives and become productive citizens who contribute a
                positive change in their communities.
              </h4>
            </div>

            <div className="services_img_one ">
              <img
                src={process.env.PUBLIC_URL + "/assets/services/service1.png"}
                alt="service1"
              />
            </div>
          </div>
        ) : words.length > 0 ? (
          words.map((txt, i) => {
            if (txt.id === 1 && txt.content !== "N") {
              return (
                <div className="services_section_one" key={i}>
                  <div className="service_description_one">
                    <h4 className="service_description_one_h4">
                      {txt.content}
                    </h4>
                  </div>
                  <div className="services_img_one">
                    <img
                      src={`${process.env.REACT_APP_API_URL}${txt.image}`}
                      alt="service1"
                    />
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div className="services_section_one">
            <div className="service_description_one">
              <h4 className="service_description_one_h4">
                Facilitate destitute, excluded and vulnerable children, mothers
                and elder people in Ethiopia to have the opportunity to improve
                their lives and become productive citizens who contribute a
                positive change in their communities.
              </h4>
            </div>

            <div className="services_img_one ">
              <img
                src={process.env.PUBLIC_URL + "/assets/services/service1.png"}
                alt="service1"
              />
            </div>
          </div>
        )}

        {Array.isArray(words) &&
        words.length > 1 &&
        words[1].content === "N" ? (
          <div className="services_section_two">
            <div className="services_img_two">
              <img
                src={process.env.PUBLIC_URL + "/assets/services/service2.jpg"}
                alt="service2"
              />
            </div>

            <div className="service_description_two">
              <h4 className="service_description_two_h4">
                ‘’Every person should have the right to basic needs, so that
                children, mothers, and elder people may have dignified, healthy,
                and secure lives.’’
              </h4>
            </div>
          </div>
        ) : words.length > 1 ? (
          words.map((txt, i) => {
            if (txt.id === 2 && txt.content !== "N") {
              return (
                <div className="services_section_two" key={i}>
                  <div className="services_img_two">
                    <img
                      src={`${process.env.REACT_APP_API_URL}${txt.image}`}
                      alt="service2"
                    />
                  </div>
                  <div className="service_description_two">
                    <h4 className="service_description_two_h4">
                      {txt.content}
                    </h4>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div className="services_section_two">
            <div className="services_img_two">
              <img
                src={process.env.PUBLIC_URL + "/assets/services/service2.jpg"}
                alt="service2"
              />
            </div>

            <div className="service_description_two">
              <h4 className="service_description_two_h4">
                ‘’Every person should have the right to basic needs, so that
                children, mothers, and elder people may have dignified, healthy,
                and secure lives.’’
              </h4>
            </div>
          </div>
        )}

        {Array.isArray(words) &&
        words.length > 2 &&
        words[2].content === "N" ? (
          <div className="services_section_three">
            <div className="service_description_three">
              <h4 className="service_description_three_h4">
                Facilitate destitute, excluded and vulnerable children, mothers
                and elder people in Ethiopia to have the opportunity to improve
                their lives and become productive citizens who contribute a
                positive change in their communities.
              </h4>
              <h4 className="service_description_three_h4">
                Fostering individual and institutional participation in
                philanthropy activities in protecting and advancing the worth
                and rights of children, mothers, and elder people.
              </h4>
            </div>

            <div className="services_img_three">
              <img
                src={process.env.PUBLIC_URL + "/assets/services/service3.png"}
                alt="service3"
              />
            </div>
          </div>
        ) : words.length > 2 ? (
          words.map((txt, i) => {
            if (txt.id === 3 && txt.content !== "N") {
              return (
                <div className="services_section_three" key={i}>
                  <div className="service_description_three">
                    <h4 className="service_description_three_h4">
                      {txt.content}
                    </h4>
                  </div>

                  <div className="services_img_three">
                    <img
                      src={`${process.env.REACT_APP_API_URL}${txt.image}`}
                      alt="service3"
                    />
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })
        ) : (
          <div className="services_section_three">
            <div className="service_description_three">
              <h4 className="service_description_three_h4">
                Facilitate destitute, excluded and vulnerable children, mothers
                and elder people in Ethiopia to have the opportunity to improve
                their lives and become productive citizens who contribute a
                positive change in their communities.
              </h4>

              <h4 className="service_description_three_h4">
                Fostering individual and institutional participation in
                philanthropy activities in protecting and advancing the worth
                and rights of children, mothers, and elder people.
              </h4>
            </div>

            <div className="services_img_three">
              <img
                src={process.env.PUBLIC_URL + "/assets/services/service3.png"}
                alt="service3"
              />
            </div>
          </div>
        )}
      </div>
      <div>
        {Array.isArray(words) &&
          words.length > 0 &&
          words
            .filter((txt) => ![1, 2, 3].includes(txt.id) && txt.content !== "N")
            .map((txt, i) => (
              <div className="services_section_two" key={i}>
                <div className="services_img_two">
                  <img
                    src={`${process.env.REACT_APP_API_URL}${txt.image}`}
                    alt="service2"
                  />
                </div>
                <div className="service_description_two">
                  <h4 className="service_description_two_h4">{txt.content}</h4>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
}

export default Services;
