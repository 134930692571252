import React, { useEffect, useState } from "react";

import HomePictureTxtOneComponent from "./HomePictureTxtOne";
import HomePictureTxtTwoComponent from "./HomePictureTxtTwo";
import HomepageAboutSM from "./HomepageAboutSM";
import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";
import Createhomepicturelandingtxt from "./Createhomepicturelandingtxt";
import axios from "axios";

const AdminHomepage = () => {
  const [selectedForm, setSelectedForm] = useState("Createhomepicture");
  const [existingRecords, setExistingRecords] = useState(0);

  // Handler for switching between forms
  const handleFormChange = (form) => {
    setSelectedForm(form);
  };

  useEffect(() => {
    const getRecords = async () => {
      try {
        const request = await axios.get(
          `${process.env.REACT_APP_API_URL}api/homepicturelandingtxt`
        );
        setExistingRecords(request.data.length);
        if (request.data.length >= 2) {
          setSelectedForm("HomePictureTxtOne");
        }
      } catch (error) {
        console.error("Error fetching existing records:", error);
      }
    };
    getRecords();
  }, []);
  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="userChoice">
          <div className="formNav">
            {/* Buttons for selecting the form */}
            {existingRecords < 2 && (
              <button
                className={selectedForm === "Createhomepicture" ? "active" : ""}
                onClick={() => handleFormChange("Createhomepicture")}
              >
                Create Home Page Picture Texts
              </button>
            )}
            <button
              className={selectedForm === "HomePictureTxtOne" ? "active" : ""}
              onClick={() => handleFormChange("HomePictureTxtOne")}
            >
              Home Page First Text
            </button>
            <button
              className={selectedForm === "HomePictureTxtTwo" ? "active" : ""}
              onClick={() => handleFormChange("HomePictureTxtTwo")}
            >
              Home Page Second Text
            </button>{" "}
            <button
              className={selectedForm === "HomepageAboutSM" ? "active" : ""}
              onClick={() => handleFormChange("HomepageAboutSM")}
            >
              Home Page About Foundation
            </button>
          </div>

          {/* Conditionally render the form based on user choice */}
          <div className="adminpageS container ">
            {existingRecords < 2 && selectedForm === "Createhomepicture" && (
              <Createhomepicturelandingtxt />
            )}
            {selectedForm === "HomePictureTxtOne" && (
              <HomePictureTxtOneComponent />
            )}
            {selectedForm === "HomePictureTxtTwo" && (
              <HomePictureTxtTwoComponent />
            )}
            {selectedForm === "HomepageAboutSM" && <HomepageAboutSM />}
          </div>
        </div>
      </div>
      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default AdminHomepage;
