import React, { useEffect, useState } from "react";

import FoundationLandingComponent from "./FoundationLanding";
import CoreValuesTitleComponent from "./CoreValuesTitle";
import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";
import UpdateCorevaluesCardComponent from "./UpdateCorevaluesCard";
import CreateCorevalues from "./CreateCorevalues";
import { api } from "../../global/Auth/api";

const AdminFoundationPage = () => {
  const [existingRecords, setExistingRecords] = useState(0);
  const [selectedForm, setSelectedForm] = useState(
    "FoundationLandingComponent"
  );

  // Handler for switching between forms
  const handleFormChange = (form) => {
    setSelectedForm(form);
  };

  useEffect(() => {
    api
      .get("api/ourcorevalues")
      .then((result) => {
        setExistingRecords(result.data.length);
      })
      .catch((error) => {
        console.error("Error fetching existing records:", error);
      });
  }, []);

  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>

      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="userChoice">
          <div className="formNav">
            {/* Buttons for selecting the form */}
            <button
              className={
                selectedForm === "FoundationLandingComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("FoundationLandingComponent")}
            >
              Foundation Landing Page
            </button>
            <button
              className={
                selectedForm === "CoreValuesTitleComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("CoreValuesTitleComponent")}
            >
              Core Values Header
            </button>
            {existingRecords < 3 && (
              <button
                className={selectedForm === "CreateCorevalues" ? "active" : ""}
                onClick={() => handleFormChange("CreateCorevalues")}
              >
                Create Core Values Content
              </button>
            )}

            <button
              className={
                selectedForm === "UpdateCorevaluesCardComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("UpdateCorevaluesCardComponent")}
            >
              Update Core Values Content
            </button>
          </div>

          <div className="adminpageS container">
            {selectedForm === "FoundationLandingComponent" && (
              <FoundationLandingComponent />
            )}
            {selectedForm === "CoreValuesTitleComponent" && (
              <CoreValuesTitleComponent />
            )}
            {existingRecords < 3 && selectedForm === "CreateCorevalues" && (
              <CreateCorevalues />
            )}
            {selectedForm === "UpdateCorevaluesCardComponent" && (
              <UpdateCorevaluesCardComponent />
            )}
          </div>
        </div>
      </div>

      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default AdminFoundationPage;
