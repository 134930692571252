import React, { useState, useEffect } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";

const UpdateSliderimage = () => {
  const [ids, setIds] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [image, setImage] = useState(null);
  const { messages, addMessage, removeMessage } = useMessage();

  useEffect(() => {
    const fetchIds = async () => {
      try {
        const response = await api.get("api/get-OurPartnersImage");
        setIds(response.data);
      } catch (error) {
        addMessage(`Failed to fetch records. ${error}`, "error");
      }
    };

    fetchIds();
  }, [addMessage]);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("image", image || null);

      // console.log("FormData:", formData); // Log the FormData object
      await api.put(`api/update-OurPartnersImage/${selectedId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      addMessage({ type: "success", text: "User Record updated successfully" });
    } catch (error) {
      addMessage(`Failed to update User Record. ${error}`, "error");
    }
  };

  return (
    <div className="Admin-form">
      <h2 className="tit__1">Update Partners Logo</h2>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon">Note</strong> If you leave this field
        Field Empty, <u className="black">the LOGO will be removed.</u>
      </p>
      <form onSubmit={handleSubmit}>
        <div className="flex">
          <label>
            <select
              className="input"
              value={selectedId}
              onChange={(e) => setSelectedId(e.target.value)}
            >
              <option value="">Select Team Record</option>
              {ids.map((record) => (
                <option key={record.id} value={record.id}>
                  {record.name}
                </option>
              ))}
            </select>
          </label>
        </div>
        <br />
        <div className="flex">
          <label>
            <input
              className="input"
              type="file"
              name="image"
              onChange={handleFileChange}
            />
          </label>
        </div>
        <br />

        <div style={{ display: "grid", justifySelf: "end" }}>
          <button className="bookmarkBtn buttonUp" type="submit">
            <span className="IconContainer">
              <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
              </svg>
            </span>
            <p className="text">Upload</p>
          </button>
        </div>
      </form>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </div>
  );
};
export default UpdateSliderimage;
