import React, { useState } from "react";

import ServiceLandingComponent from "./ServiceLanding";
import OurcampaignstitleComponent from "./Ourcampaignstitle";
import OurcampaignsContentComponent from "./OurcampaignsContent";
import UpdateContentContentComponent from "./UpdateContent";
import "./AdminService.css";
import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";

const AdminservicePage = () => {
  // State to track which form is selected
  const [selectedForm, setSelectedForm] = useState("ServiceLandingComponent");

  // Handler for switching between forms
  const handleFormChange = (form) => {
    setSelectedForm(form);
  };
  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>

      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="userChoice">
          <div className="formNav">
            {/* Buttons for selecting the form */}
            <button
              className={
                selectedForm === "ServiceLandingComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("ServiceLandingComponent")}
            >
              Services Landing Page Header
            </button>
            <button
              className={
                selectedForm === "OurcampaignstitleComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("OurcampaignstitleComponent")}
            >
              Update Campaigns Title
            </button>
            <button
              className={
                selectedForm === "OurcampaignsContentComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("OurcampaignsContentComponent")}
            >
              Our Campaigns Section
            </button>

            <button
              className={
                selectedForm === "UpdateContentContentComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("UpdateContentContentComponent")}
            >
              Campaign Records
            </button>
          </div>

          <div className="adminpageS container">
            {selectedForm === "ServiceLandingComponent" && (
              <ServiceLandingComponent />
            )}
            {selectedForm === "OurcampaignstitleComponent" && (
              <OurcampaignstitleComponent />
            )}
            {selectedForm === "OurcampaignsContentComponent" && (
              <OurcampaignsContentComponent />
            )}

            {selectedForm === "UpdateContentContentComponent" && (
              <UpdateContentContentComponent />
            )}
          </div>
        </div>
      </div>

      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default AdminservicePage;
