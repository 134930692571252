import React, { useState, useEffect } from "react";

import axios from "axios";

import "./Slider.css";

const Slider = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/get-OurPartnersImage`;
    // Fetch Data
    axios
      .get(apiUrl)
      .then((result) => {
        setData(
          result.data.map((item) => ({
            id: item.id,
            image: item.image,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="tag-list container">
      <div className="inner">
        {data.length >= 3 &&
          Array.from({ length: data.length * 3 }).map((_, i) => {
            // Determine the actual index of the data to be used
            const actualIndex = i % data.length;
            const record = data[actualIndex];

            return (
              <div key={i} className="tag">
                <img
                  src={`${process.env.REACT_APP_API_URL}${record.image}`}
                  alt={`${i}-img`}
                />
                <p>hi</p>
              </div>
            );
          })}
        {data.length < 3 && (
          <>
            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/ethiotel.png"
                }
                alt="OurPartners-1"
              />
            </div>
            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/hebretBank.png"
                }
                alt="OurPartners-2"
              />
            </div>
            <div className="tag">
              <img
                src={process.env.PUBLIC_URL + "/assets/OurPartners/CBE.png"}
                alt="OurPartners-3"
              />
            </div>
            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/DashenBank.png"
                }
                alt="OurPartners-4"
              />
            </div>
            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/telebirr.png"
                }
                alt="OurPartners-5"
              />
            </div>

            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/ethiotel.png"
                }
                alt="OurPartners-4"
              />
            </div>
            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/hebretBank.png"
                }
                alt="OurPartners-5"
              />
            </div>
            <div className="tag">
              <img
                src={process.env.PUBLIC_URL + "/assets/OurPartners/CBE.png"}
                alt="OurPartners-6"
              />
            </div>
            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/DashenBank.png"
                }
                alt="OurPartners-7"
              />
            </div>
            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/telebirr.png"
                }
                alt="OurPartners-8"
              />
            </div>

            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/ethiotel.png"
                }
                alt="OurPartners-9"
              />
            </div>
            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/hebretBank.png"
                }
                alt="OurPartners-10"
              />
            </div>
            <div className="tag">
              <img
                src={process.env.PUBLIC_URL + "/assets/OurPartners/CBE.png"}
                alt="OurPartners-11"
              />
            </div>
            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/DashenBank.png"
                }
                alt="OurPartners-12"
              />
            </div>
            <div className="tag">
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/OurPartners/telebirr.png"
                }
                alt="OurPartners-13"
              />
            </div>
          </>
        )}
      </div>
      <div className="fade"></div>
    </div>
  );
};

export default Slider;
