import React, { useEffect, useState } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";
import axios from "axios";
import HelperCard from "../HelperCard/HelperCard";

const AddTeamMemberForm = () => {
  const [existingRecords, setExistingRecords] = useState(0);

  const [name, setName] = useState("");
  const [persontitle, setPersonTitle] = useState("");
  const [facebook, setFacebook] = useState("");
  const [x, setX] = useState("");
  const [linkedln, setlinkedln] = useState("");
  const [image, setImage] = useState(null);
  const { messages, addMessage, removeMessage } = useMessage();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name.length >= 100 || persontitle.length >= 100) {
      return addMessage(
        "name and Person Title must be under 200 characters",
        "error"
      );
    }
    if (facebook.length >= 100 || x.length >= 100 || linkedln.length >= 100) {
      return addMessage("Team contacts must be under 100 characters", "error");
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("persontitle", persontitle);
    formData.append("facebook", facebook);
    formData.append("x", x);
    formData.append("linkedln", linkedln);
    formData.append("image", image);

    try {
      await api.post("api/Create-ourboardteamsdata", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setName("");
      setPersonTitle("");
      setFacebook("");
      setX("");
      setlinkedln("");
      setImage(null);
      addMessage("User Record Registered successfully", "success");
    } catch (error) {
      addMessage(`Error adding data: ${error}`, "error");
    }
  };

  useEffect(() => {
    const getRecords = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/allourboardteamsdata`
        );
        setExistingRecords(response.data.length);
      } catch (error) {
        console.error("Error fetching existing records:", error);
      }
    };
    getRecords();
  }, []);
  return (
    <>
      {existingRecords >= 11 ? (
        <HelperCard
          title="Board Members Adding Form"
          description="You have already created a total of 11 Board Members. Please use the updater form to make any updates to the existing members at the next tab here."
          others={
            <button className="HelperCard-accept">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-arrow-big-right-dash"
              >
                <path d="M5 9v6" />
                <path d="M9 9h3V5l7 7-7 7v-4H9V9z" />
              </svg>
            </button>
          }
        />
      ) : (
        <div className="Admin-form">
          <h2 className="tit__1">Add Team Member</h2>

          <p className="Expensivemessage">
            <strong className="ExpensiveIcon">Note</strong> You can only create
            a total of <u className="black">11 Members</u>
          </p>
          <br />
          <form onSubmit={handleSubmit}>
            <div className="name_and_title">
              <div className="flex">
                <label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="flex">
                <label>
                  <input
                    className="input"
                    type="text"
                    placeholder="Person Title"
                    id="persontitle"
                    value={persontitle}
                    onChange={(e) => setPersonTitle(e.target.value)}
                    autoComplete="off"
                  />
                </label>
              </div>
            </div>

            <br />

            <div className="flex">
              <label>
                <input
                  className="input"
                  type="text"
                  placeholder="Facebook Link"
                  id="facebook"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                  autoComplete="off"
                />
              </label>
            </div>

            <br />

            <div className="flex">
              <label>
                <input
                  className="input"
                  type="text"
                  placeholder="X Link"
                  id="x"
                  value={x}
                  onChange={(e) => setX(e.target.value)}
                  autoComplete="off"
                />
              </label>
            </div>

            <br />

            <div className="flex">
              <label>
                <input
                  className="input"
                  type="text"
                  placeholder="linkedln Link"
                  id="linkedln"
                  value={linkedln}
                  onChange={(e) => setlinkedln(e.target.value)}
                  autoComplete="off"
                />
              </label>
            </div>

            <br />

            <div className="flex">
              {" "}
              <label>
                {" "}
                <input
                  className="input"
                  type="file"
                  name="image"
                  onChange={(e) => setImage(e.target.files[0])}
                />{" "}
              </label>{" "}
            </div>

            <br />

            <div style={{ display: "grid", justifySelf: "end" }}>
              <button className="bookmarkBtn buttonUp">
                <span className="IconContainer">
                  <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                    <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
                  </svg>
                </span>
                <p className="text">Create</p>
              </button>
            </div>
          </form>
          <MessageComponent messages={messages} removeMessage={removeMessage} />
        </div>
      )}
    </>
  );
};

export default AddTeamMemberForm;
