import React, { useState, useEffect } from "react";

import axios from "axios";

const FoundationHeader = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    fatchData();
  }, []);

  // get the data
  const fatchData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/foundationpicturetxt`;
      const result = await axios(apiUrl);
      setdata(result.data);
    } catch (err) {
      console.log("something wrong");
    }
  };
  return (
    <>
      <div className="teamsHome_section container">
        <div className="teamSection_title founderHome">
          {Array.isArray(data) && data.length > 0 && data[0].title === "N" ? (
            <h1 className="teamSection_title_h1 ">The Founders</h1>
          ) : data.length > 0 ? (
            data.map((txt, i) => (
              <h1 className="teamSection_title_h1" key={i}>
                {txt.title}
              </h1>
            ))
          ) : (
            <h1 className="teamSection_title_h1">The Founders</h1>
          )}
        </div>

        <div className="teams_moto">
          {Array.isArray(data) && data.length > 0 && data[0].content === "N" ? (
            <p className="foundaion_intro">
              Our daughter passed away at 9 in 2013 (Ethiopian Calendar) or 2021
              (Gregorian Calendar). To honor her, we created an organization,
              securing startup funds and aiding the marginalized, focusing on
              children, mothers, and the elderly. This initiative reflects our
              enduring love for our child, offering solace and support in her
              memory.
            </p>
          ) : data.length > 0 ? (
            data.map((txt, i) => (
              <p className="foundaion_intro" key={i}>
                {txt.content}
              </p>
            ))
          ) : (
            <p className="foundaion_intro">
              Our daughter passed away at 9 in 2013 (Ethiopian Calendar) or 2021
              (Gregorian Calendar). To honor her, we created an organization,
              securing startup funds and aiding the marginalized, focusing on
              children, mothers, and the elderly. This initiative reflects our
              enduring love for our child, offering solace and support in her
              memory.
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default FoundationHeader;
