import React, { useState } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";

const Location = () => {
  const { messages, addMessage, removeMessage } = useMessage();
  const [title, setTitle] = useState("");
  const [degree, setDegree] = useState("");
  const [position, setPosition] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title.length >= 50 || degree.length >= 50 || position.length >= 300) {
      return addMessage(
        "Title, Degree and Position must be under 50 and 300 characters",
        "error"
      );
    }
    try {
      await api.post("api/updatemaplocationService", {
        title,
        degree,
        position,
      });
      addMessage("Record updated successfully", "success");
      // console.log(response.data.message);
    } catch (error) {
      console.error("Error updating text content:", error);
      addMessage(`An error occurred. Please try again. ${error}`, "error");
    }
  };

  return (
    <form
      className="Admin-form"
      onSubmit={handleSubmit}
      style={{ height: "fit-content" }}
    >
      <h2 className="tit__1">Update Headquarters Location</h2>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon">Note</strong> If you leave these Field
        Empty, <u className="black">the default value will be displayed.</u>
      </p>
      <div className="flex">
        <label>
          <input
            className="input"
            type="text"
            placeholder="Section Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </label>
      </div>
      <div className="flex">
        <label>
          <input
            placeholder="GPS coordinates"
            name="text"
            className="input"
            type="text"
            value={degree}
            onChange={(e) => setDegree(e.target.value)}
          ></input>
        </label>
      </div>
      <div className="flex">
        <label>
          <input
            placeholder="Place / Building"
            name="text"
            className="input"
            type="text"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          ></input>
        </label>
      </div>
      <button className="bookmarkBtn">
        {" "}
        <span className="IconContainer">
          {" "}
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            {" "}
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Submit</p>
      </button>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </form>
  );
};

export default Location;
