import React, { useState } from "react";

import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";
import FooterContentFormComponent from "./FooterContentForm";
import FoundationSocialMedias from "./FoundationSocialMedias";

const AdminFooterpage = () => {
  // State to track which form is selected
  const [selectedForm, setSelectedForm] = useState(
    "FooterContentFormComponent"
  );

  // Handler for switching between forms
  const handleFormChange = (form) => {
    setSelectedForm(form);
  };
  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="userChoice">
          <div className="formNav">
            {/* Buttons for selecting the form */}
            <button
              className={
                selectedForm === "FooterContentFormComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("FooterContentFormComponent")}
            >
              Footer Contents
            </button>
            <button
              className={
                selectedForm === "FoundationSocialMedias" ? "active" : ""
              }
              onClick={() => handleFormChange("FoundationSocialMedias")}
            >
              Foundation Social Medias
            </button>
          </div>

          <div className="adminpageS container">
            {selectedForm === "FooterContentFormComponent" && (
              <FooterContentFormComponent />
            )}
            {selectedForm === "FoundationSocialMedias" && (
              <FoundationSocialMedias />
            )}
          </div>
        </div>
      </div>
      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default AdminFooterpage;
