import "./admin.css";
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useAuth } from "../global/Auth/AuthProvider";

function TopBar() {
  const [Toggle, showMenu] = useState(false);

  const { logoutAction } = useAuth(); // Access the logOut function from AuthContext

  const handleLogout = () => {
    logoutAction(); // Call the logOut function to log the user out
    // Perform any additional actions to clear user data from the browser
    sessionStorage.removeItem("accessToken"); // Remove the token from localStorage
    // Refresh the browser
    window.location.reload();
  };

  return (
    <nav>
      <Link to="/" className="nav__logos">
        <img
          src={process.env.PUBLIC_URL + "/assets/logo/sam-logo-12.png"}
          alt="Samrawit Logo"
        />
      </Link>
      <div>
        <ul id="navbar" className={Toggle ? "active" : ""}>
          <li>
            <NavLink exact="true" to="/" activeClassName="active">
              Visit Website
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/login"
              onClick={handleLogout}
              activeClassName="active red"
            >
              Logout
              <span className="red">
                {" "}
                &nbsp;{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ff0000"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-log-out"
                >
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                  <polyline points="16 17 21 12 16 7" />
                  <line x1="21" x2="9" y1="12" y2="12" />
                </svg>
              </span>
            </NavLink>
          </li>
        </ul>
      </div>

      <div
        id="mobile"
        onClick={() => {
          showMenu(!Toggle);
        }}
      >
        {Toggle ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ff0000"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-list-minus"
          >
            <path d="M11 12H3" />
            <path d="M16 6H3" />
            <path d="M16 18H3" />
            <path d="M21 12h-6" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ff0000"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-list-minus"
          >
            <path d="M11 12H3" />
            <path d="M16 6H3" />
            <path d="M16 18H3" />
            <path d="M21 12h-6" />
          </svg>
        )}
      </div>
    </nav>
  );
}

export default TopBar;
