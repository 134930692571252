import React, { useState, useEffect } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";
import HelperCard from "../HelperCard/HelperCard";

const AddProfessionForm = () => {
  const [category, setCategory] = useState("");
  const { messages, addMessage, removeMessage } = useMessage();
  const [categoryCount, setCategoryCount] = useState(0);

  const [existingRecords, setExistingRecords] = useState(0);

  useEffect(() => {
    const fetchCategoryCount = async () => {
      try {
        const response = await api.get("api/allProffesionsdata");
        setCategoryCount(response.data.length);
        setExistingRecords(response.data.length);
      } catch (error) {
        addMessage(`Error fetching records: ${error}`, "error");
      }
    };

    fetchCategoryCount();
  }, [addMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (category.length >= 200) {
      return addMessage("Category must be under 200 characters", "error");
    }
    if (categoryCount >= 4) {
      return addMessage("Maximum number of categories reached", "error");
    }

    try {
      await api.post("api/createAddProfession", { category });
      setCategory("");
      setCategoryCount((prevCount) => prevCount + 1);
      addMessage("Category added successfully", "success");
    } catch (error) {
      addMessage(`Error adding category: ${error.message}`, "error");
    }
  };

  return (
    <>
      {existingRecords >= 4 ? (
        <HelperCard
          title="Boarders Category"
          description="You have already created four categories. While adding additional categories is not possible, you can update the existing ones in the next tab."
          others={
            <button className="HelperCard-accept">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-arrow-big-right-dash"
              >
                <path d="M5 9v6" />
                <path d="M9 9h3V5l7 7-7 7v-4H9V9z" />
              </svg>
            </button>
          }
        />
      ) : (
        <form
          className="Admin-form"
          style={{ height: "fit-content" }}
          onSubmit={handleSubmit}
        >
          <h2 className="tit__1">Create Team Category</h2>
          <p className="Expensivemessage">
            <strong className="ExpensiveIcon">Note </strong>{" "}
            <u className="red">You can create only 4 Categorys</u>
          </p>
          <div className="flex">
            <label>
              <input
                className="input"
                type="text"
                placeholder="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />{" "}
            </label>
          </div>
          <button className="bookmarkBtn">
            <span className="IconContainer">
              <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
              </svg>
            </span>
            <p className="text">Create</p>
          </button>
          <MessageComponent messages={messages} removeMessage={removeMessage} />
        </form>
      )}
    </>
  );
};

export default AddProfessionForm;
