import React, { useState, useEffect } from "react";

import { api } from "./global/Auth/api";

import Sidebar from "./global/Sidebar";
import TopBar from "./global/TopBar";
import Footer from "../components/footer/Footer";
import LandingPagePicturesComponent from "./adminComponents/homepage/UploadLandingPagePictures";
import UpdateLandingPagePictureFormComponent from "./adminComponents/homepage/UpdateLandingPagePictureForm";
import HelperCard from "./adminComponents/HelperCard/HelperCard";

const Admin = () => {
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("api/LandingPagePicture");

        setDataLength(response.data.length);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="adminpageS container Admincenter">
          <div style={{ display: dataLength < 6 ? "none" : "block" }}>
            <HelperCard
              title="Landing Pages Picture"
              description="Each page includes a dedicated landing page picture. You can now update these pictures using the form on the right-hand side."
              others={
                <button className="HelperCard-accept">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-arrow-big-right-dash"
                  >
                    <path d="M5 9v6" />
                    <path d="M9 9h3V5l7 7-7 7v-4H9V9z" />
                  </svg>
                </button>
              }
            />
          </div>
          <div className="grid-horizontal">
            {/* Conditionally hide the component if dataLength is greater than or equal to 6 */}
            <div style={{ display: dataLength > 6 ? "none" : "block" }}>
              <LandingPagePicturesComponent dataLength={dataLength} />
            </div>
            <UpdateLandingPagePictureFormComponent />
          </div>
        </div>
      </div>
      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default Admin;
