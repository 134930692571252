import React, { useState } from "react";

import "./header.css";
import { Link, NavLink } from "react-router-dom";

function Header() {
  const [Toggle, showMenu] = useState(false); // Changed state variable name

  return (
    <nav>
      <Link to="/" className="nav__logos">
        <img
          src={process.env.PUBLIC_URL + "/assets/logo/sam-logo-12.png"}
          alt="Samrawit Logo"
        />
      </Link>
      <div>
        <ul id="navbar" className={Toggle ? "active" : ""}>
          <li>
            <NavLink exact="true" to="/" activeClassName="active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/foundation" activeClassName="active">
              Foundation
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" activeClassName="active">
              About&nbsp;Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/galleries" activeClassName="active">
              Galleries
            </NavLink>
          </li>
          <li>
            <NavLink to="/services" activeClassName="active">
              Services
            </NavLink>
          </li>
          <li>
            <NavLink to="/DonatePage" className="Donateactive">
              <strong>Donate</strong>
            </NavLink>
          </li>
        </ul>
      </div>

      <div
        id="mobile"
        onClick={() => {
          showMenu(!Toggle);
        }}
      >
        {Toggle ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ff0000"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-list-minus"
          >
            <path d="M11 12H3" />
            <path d="M16 6H3" />
            <path d="M16 18H3" />
            <path d="M21 12h-6" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ff0000"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-list-minus"
          >
            <path d="M11 12H3" />
            <path d="M16 6H3" />
            <path d="M16 18H3" />
            <path d="M21 12h-6" />
          </svg>
        )}
      </div>
    </nav>
  );
}

export default Header;
