import React, { useEffect, useState } from "react";

import axios from "axios";

import "./theFoundation.css";
import Header from "../../components/header/Header";
import Objectives from "../../components/Foundations/Objectives";
import FoundationHeader from "../../components/Foundations/FoundationHeader";
import Footer from "../../components/footer/Footer";
import LandingPagesPicture from "../../Data/LandingPages";

const Thefoundation = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/LandingPagePicture`;
        const response = await axios.get(apiUrl);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const foundationPicture = LandingPagesPicture.find((page) => page.id === "2");

  return (
    <div className="fullpageStyle">
      <div className="">
        {data.length >= 2 && data[1].image !== "N" ? (
          <div className="">
            <Header />
            <div className="FoundationHeaderback">
              <img
                className="FullWindowImage"
                src={`${process.env.REACT_APP_API_URL}${data[1].image}`}
                alt="FoundationLanding-page"
              />
            </div>
            <div className="container">
              <FoundationHeader />
            </div>
          </div>
        ) : (
          <div className="">
            <Header />
            <div className="FoundationHeaderback">
              <img
                className="FullWindowImage"
                src={foundationPicture.img}
                alt={foundationPicture.title}
              />
            </div>
            <div className="container">
              <FoundationHeader />
            </div>
          </div>
        )}
      </div>

      <div className="">
        <Objectives />
      </div>

      <div className="footerDIV">
        <Footer />
      </div>
    </div>
  );
};

export default Thefoundation;
