import React, { useState } from "react";

import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";
import UpdateGetInTouchUs from "./UpdateGetInTouchUs ";
import LocationComponent from "./Location";

const AdminContactPage = () => {
  // State to track which form is selected
  const [selectedForm, setSelectedForm] = useState("LocationComponent");

  // Handler for switching between forms
  const handleFormChange = (form) => {
    setSelectedForm(form);
  };
  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="userChoice">
          <div className="formNav">
            {/* Buttons for selecting the form */}
            <button
              className={selectedForm === "LocationComponent" ? "active" : ""}
              onClick={() => handleFormChange("LocationComponent")}
            >
              Update Headquarters Location
            </button>
            <button
              className={selectedForm === "UpdateGetInTouchUs" ? "active" : ""}
              onClick={() => handleFormChange("UpdateGetInTouchUs")}
            >
              Get In Touch Us
            </button>
          </div>

          <div className="adminpageS container">
            {selectedForm === "LocationComponent" && <LocationComponent />}
            {selectedForm === "UpdateGetInTouchUs" && <UpdateGetInTouchUs />}
          </div>
        </div>
      </div>
      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default AdminContactPage;
