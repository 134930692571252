import React, { useEffect, useState } from "react";

import UpdateBoardTeamMemberComponent from "./UpdateBoardTeamMember";
import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";
import LevelofauthoritytitlesComponent from "./Levelofauthoritytitles";
import AddTeamMemberFormComponent from "./AddTeamMemberForm";
import AddProfessionForm from "./AddProfessionForm";
import axios from "axios";

const AdminTeamsPage = () => {
  // State to track which form is selected
  const [selectedForm, setSelectedForm] = useState(
    "UpdateBoardTeamMemberComponent"
  );

  // Handler for switching between forms
  const handleFormChange = (form) => {
    setSelectedForm(form);
  };

  const [existingRecords, setExistingRecords] = useState(0);
  const [ExistingRecordsProffesions, setExistingRecordsProffesions] =
    useState(0);

  useEffect(() => {
    const getRecords = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/allourboardteamsdata`
        );
        setExistingRecords(response.data.length);
      } catch (error) {
        console.error("Error fetching existing records:", error);
      }
    };
    getRecords();
  }, []);

  useEffect(() => {
    const getRecords = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/allProffesionsdata`
        );
        setExistingRecordsProffesions(response.data.length);
      } catch (error) {
        console.error("Error fetching existing records:", error);
      }
    };
    getRecords();
  }, []);

  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="userChoice">
          <div className="formNav">
            {/* Buttons for selecting the form */}
            {existingRecords < 11 && (
              <button
                className={
                  selectedForm === "AddTeamMemberFormComponent" ? "active" : ""
                }
                onClick={() => handleFormChange("AddTeamMemberFormComponent")}
              >
                Add Team Member
              </button>
            )}

            {ExistingRecordsProffesions < 4 && (
              <button
                className={selectedForm === "AddProfessionForm" ? "active" : ""}
                onClick={() => handleFormChange("AddProfessionForm")}
              >
                Add Profession Categories
              </button>
            )}
            <button
              className={
                selectedForm === "UpdateBoardTeamMemberComponent"
                  ? "active"
                  : ""
              }
              onClick={() => handleFormChange("UpdateBoardTeamMemberComponent")}
            >
              Update Board Member Contact Info
            </button>
            <button
              className={
                selectedForm === "LevelofauthoritytitlesComponent"
                  ? "active"
                  : ""
              }
              onClick={() =>
                handleFormChange("LevelofauthoritytitlesComponent")
              }
            >
              Update Members Hierarchy Title
            </button>
          </div>

          <div className="adminpageS container">
            {existingRecords < 11 &&
              selectedForm === "AddTeamMemberFormComponent" && (
                <AddTeamMemberFormComponent />
              )}

            {ExistingRecordsProffesions < 4 &&
              selectedForm === "AddProfessionForm" && <AddProfessionForm />}

            {selectedForm === "UpdateBoardTeamMemberComponent" && (
              <UpdateBoardTeamMemberComponent />
            )}
            {selectedForm === "LevelofauthoritytitlesComponent" && (
              <LevelofauthoritytitlesComponent />
            )}
          </div>
        </div>
      </div>
      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default AdminTeamsPage;
