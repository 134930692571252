import React, { useState, useEffect } from "react";

import axios from "axios";

import "./Gallaries.css";

function GallariesHome() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    fatchData();
  }, []);

  const fatchData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/gallariespicturetxt`;
      const result = await axios(apiUrl);
      setdata(result.data);
    } catch (err) {
      console.log("something wrong", err);
    }
  };
  return (
    <>
      <div className="teamsHome_section container">
        <div className="teamSection_title founderHome">
          {Array.isArray(data) && data.length > 0 && data[0].title === "N" ? (
            <h1 className="teamSection_title_h1 ">Gallaries</h1>
          ) : data.length > 0 ? (
            data.map((txt, i) => (
              <h1 className="teamSection_title_h1" key={i}>
                {txt.title}
              </h1>
            ))
          ) : (
            <h1 className="teamSection_title_h1 ">Gallaries</h1>
          )}
        </div>
        <div className="teams_moto">
          {Array.isArray(data) && data.length > 0 && data[0].content === "N" ? (
            <p className="foundaion_intro">
              Help disadvantaged children, mothers and elder people in Ethiopia
              to thrive and make a difference in their communities.
            </p>
          ) : data.length > 0 ? (
            data.map((txt, i) => (
              <p className="foundaion_intro" key={i}>
                {txt.content}
              </p>
            ))
          ) : (
            <p className="foundaion_intro">
              Help disadvantaged children, mothers and elder people in Ethiopia
              to thrive and make a difference in their communities.
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default GallariesHome;
