// api.js
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true, // Include cookies in requests
});

const setupInterceptors = () => {
  // Add a request interceptor
  api.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem("accessToken");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        console.log("No access token found in Storages.");
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add a response interceptor
  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          // use refresh token to update the access token
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/accessToken`,
            {}, // No body needed since the refresh token is in the cookie
            { withCredentials: true } // Send cookies with the request
          );

          // Save new access token and retry original request
          const { accessToken } = response.data;
          sessionStorage.setItem("accessToken", accessToken);
          originalRequest.headers.Authorization = `Bearer ${accessToken}`; // Retry the original request with the new token

          return axios(originalRequest); // Retry the request
        } catch (refreshError) {
          // If refresh fails, logout and redirect to login
          // console.error("Refresh token expired. Redirecting to login.");
          sessionStorage.clear(); // Clear tokens
          window.location.href = "/login"; // Redirect to login
          return Promise.reject(refreshError);
        }
      }

      // Handle 403 (forbidden) error
      if (error.response?.status === 403) {
        console.error("Invalid token, redirecting to login...");
        sessionStorage.clear(); // Clear session storage
        window.location.href = "/#/login"; // Redirect to login
        return Promise.reject(error);
      }

      // Return other errors as is
      return Promise.reject(error);
    }
  );
};

export { api, setupInterceptors };
