import React, { useState, useEffect } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";

const UpdateLandingPagePictureForm = () => {
  const [selectedId, setSelectedId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [ids, setIds] = useState([]);
  const { messages, addMessage, removeMessage } = useMessage();

  useEffect(() => {
    const fetchIds = async () => {
      try {
        const response = await api.get("api/LandingPagePicture");
        setIds(response.data.map(({ id }) => id)); // returns array of objects with an 'id' field
      } catch (error) {
        addMessage(`Failed to fetch records. ${error}`, "error");
      }
    };

    fetchIds();
  }, [addMessage]);
  // Create a mapping between ID and Landing Page Names
  const idToPageName = {
    1: "Home Landing Image",
    2: "Foundation Landing Image",
    3: "About Us Landing Image",
    4: "Galleries Landing Image",
    5: "Services Landing Image",
    6: "Donate Landing Image",
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedId) {
      addMessage("Please select a Landing Page", "error");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedFile);

    try {
      await api.patch(
        `api/update-LandingPage-picture/${selectedId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      addMessage("Image updated successfully!", "success");
    } catch (error) {
      addMessage(`Error updating image: ${error}`, "error");
    }
  };

  return (
    <div className="Admin-form" style={{ height: "fit-content" }}>
      <h2 className="tit__1">Update Landing Page Picture</h2>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon"> + </strong> If you leave this Image
        field empty,{" "}
        <u className="black">the default value will be displayed.</u>
      </p>

      <form onSubmit={handleSubmit}>
        <div className="Adminflex">
          <label htmlFor="id">
            <select
              className="input"
              value={selectedId}
              onChange={(e) => setSelectedId(e.target.value)}
            >
              <option value="">Select Landing Page Record</option>
              {ids &&
                ids.map((id) => (
                  <option key={id} value={id}>
                    {idToPageName[id] || `Page ${id}`}
                  </option>
                ))}
            </select>
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="file"
              name="image"
              onChange={handleFileChange}
            />
          </label>
        </div>
        <br />

        <div style={{ display: "grid", justifySelf: "end" }}>
          <button className="bookmarkBtn buttonUp">
            <span className="IconContainer">
              <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
              </svg>
            </span>
            <p className="text">Upload</p>
          </button>
        </div>
      </form>

      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </div>
  );
};

export default UpdateLandingPagePictureForm;
