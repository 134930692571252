import React, { useState, useEffect } from "react";

import axios from "axios";

import AllusersComponent from "./Allusers";
import CreatuserComponent from "./Creatuser";
import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";
import useMessage from "../message/useMessage";
import MessageComponent from "../message/MessageComponent";
import HelperCard from "../HelperCard/HelperCard";

const Users = () => {
  const { messages, addMessage, removeMessage } = useMessage();
  const [allUsers, setAllUsers] = useState(0);
  // Fetch user count on component mount
  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/users/count`
        );

        if (response.status === 200) {
          setAllUsers(response.data.users);
        }
      } catch (error) {
        if (error.response) {
          console.log("Error:", error.response.data.message);
          addMessage(error.response.data.message, "error");
        } else {
          addMessage("An unexpected error occurred", "error");
        }
      }
    };

    fetchUserCount();
  }, [addMessage]);

  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>
      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="adminpageS container Admincenter">
          {allUsers <= 3 && <CreatuserComponent />}
          {allUsers > 3 && (
            <HelperCard
              title="Account Policy"
              description="There are currently only 4 administrators allowed on this platform. To add a new administrator, you will need to remove an existing one from the list."
              others={
                <button className="HelperCard-accept">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-arrow-big-right-dash"
                  >
                    <path d="M5 9v6" />
                    <path d="M9 9h3V5l7 7-7 7v-4H9V9z" />
                  </svg>
                </button>
              }
            />
          )}

          <AllusersComponent />
        </div>
      </div>
      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </div>
  );
};

export default Users;
