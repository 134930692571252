import React, { useEffect, useState } from "react";

import axios from "axios";

import "./Description.css";

const Description = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/homepicturelandingtxt`;
    axios
      .get(apiUrl)
      .then((result) => {
        if (Array.isArray(result.data) && result.data.length > 0) {
          const firstRecord = result.data[0];
          setdata([{ id: firstRecord.id, content: firstRecord.content }]);
        } else {
          setdata([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/LandingPagePicture`;
        await axios.get(apiUrl);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="foundation_discription">
        {Array.isArray(data) && data.length > 0 && data[0].content === "N" ? (
          <h1 className="dis">
            EMPOWERING HOPE, JOINING HANDS TO CHANGE THE LIVES OF DESTITUTE
            CHILDREN, MARGINALIZED ELDERS AND DISABLED PEOPLE
          </h1>
        ) : data.length > 0 ? (
          data.map((txt, i) => (
            <h1 className="dis" key={i}>
              {" "}
              {txt.content}{" "}
            </h1>
          ))
        ) : (
          <h1 className="dis">
            EMPOWERING HOPE, JOINING HANDS TO CHANGE THE LIVES OF DESTITUTE
            CHILDREN, MARGINALIZED ELDERS AND DISABLED PEOPLE
          </h1>
        )}
      </div>
    </>
  );
};

export default Description;
