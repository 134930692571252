import React, { useState } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";

const FoundationSocialMedias = () => {
  const [youtube, setYoutube] = useState("");
  const [x, setX] = useState("");
  const [telegram, setTelegram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [linkedln, setLinkedln] = useState("");
  const { messages, addMessage, removeMessage } = useMessage();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      youtube.length >= 100 ||
      x.length >= 100 ||
      telegram.length >= 100 ||
      facebook.length >= 100 ||
      tiktok.length >= 100 ||
      linkedln.length >= 100
    ) {
      return addMessage("filds must be under 100 characters", "error");
    }

    try {
      await api.post("api/updateFoundationlinks", {
        youtube,
        x: x,
        telegram: telegram,
        facebook: facebook,
        tiktok: tiktok,
        linkedln: linkedln,
      });
      addMessage("Social Contacts updated successfully", "success");
    } catch (error) {
      addMessage(`Failed to update social contacts: ${error}`, "error");
    }
  };

  return (
    <div className="Admin-form" style={{ height: "fit-content" }}>
      <h2 className="tit__1">All Social Media links</h2>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon">Note</strong> If you leave this Field
        Empty, <u className="black">the default value will be displayed.</u>
      </p>
      <br />
      <form onSubmit={handleSubmit}>
        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              id="youtube"
              placeholder="Youtube Link"
              value={youtube}
              onChange={(e) => setYoutube(e.target.value)}
            />{" "}
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="X Link"
              id="x"
              value={x}
              onChange={(e) => setX(e.target.value)}
            />
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="Telegram Link"
              id="telegram"
              value={telegram}
              onChange={(e) => setTelegram(e.target.value)}
            />
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="Facebook Link"
              id="facebook"
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
            />
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="Tiktok Link"
              id="tiktok"
              value={tiktok}
              onChange={(e) => setTiktok(e.target.value)}
            />
          </label>
        </div>
        <br />

        <div className="flex">
          <label>
            <input
              className="input"
              type="text"
              placeholder="Linkedln Link"
              id="linkedln"
              value={linkedln}
              onChange={(e) => setLinkedln(e.target.value)}
            />
          </label>
        </div>
        <br />

        <div style={{ display: "grid", justifySelf: "end" }}>
          <button className="bookmarkBtn buttonUp">
            <span className="IconContainer">
              <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
              </svg>
            </span>
            <p className="text">Upload</p>
          </button>
        </div>
      </form>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </div>
  );
};
export default FoundationSocialMedias;
