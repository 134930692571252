import React, { useState, useEffect } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";
import "../User/CreateUser.css";

const UpdateCorevaluesCard = () => {
  const [ids, setIds] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const { messages, addMessage, removeMessage } = useMessage();

  useEffect(() => {
    const fetchIds = async () => {
      try {
        const response = await api.get("api/Ourcorevalues");
        setIds(response.data);
        const idsArray = response.data.map((item) => item.id);
        setIds(idsArray);
      } catch (error) {
        addMessage(`Failed to fetching record. ${error}`, "error");
        console.error("Error fetching IDs:", error);
      }
    };

    fetchIds();
  }, [addMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.post(`api/updateOurcorevalues/${selectedId}`, {
        title,
        content,
      });
      addMessage("Record updated successfully", "success");
    } catch (error) {
      console.error("Error updating record:", error);
      addMessage(`Failed to update record. ${error}`, "error");
    }
  };

  return (
    <form
      className="Admin-form"
      onSubmit={handleSubmit}
      style={{ height: "fit-content" }}
    >
      <h2 className="tit__1">Update Core Values Content</h2>

      <p className="Expensivemessage">
        <strong className="ExpensiveIcon">Note</strong> If you updates this
        title and text area empty,{" "}
        <u className="black">the default value will be displayed.</u>
      </p>
      <div className="Admin-dropdown">
        <label>
          <select
            className="input"
            value={selectedId}
            onChange={(e) => setSelectedId(e.target.value)}
          >
            <option value="">Select Card</option>
            {ids.map((id, i) => (
              <option key={i} value={id}>
                Card {id}
              </option>
            ))}
          </select>
        </label>
      </div>
      <label>
        <input
          className="input"
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </label>
      <div className="Admininput-wrapper">
        <textarea
          placeholder="Description"
          name="text"
          className="Admininput"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        ></textarea>
      </div>
      <button className="bookmarkBtn" type="submit" disabled={!selectedId}>
        <span className="IconContainer">
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Update</p>
      </button>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </form>
  );
};

export default UpdateCorevaluesCard;
