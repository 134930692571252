import React, { useEffect, useState } from "react";

import axios from "axios";

import "./Donate.css";
import Header from "../../components/header/Header";
import ContactUsHeader from "../../components/contact/DonateHeader";
import Footer from "../../components/footer/Footer";
import LandingPagesPicture from "../../Data/LandingPages";
import BankCards from "./BankCards";
import MpgsCommingsoon from "./MpgsCommingsoon";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/LandingPagePicture`;
        const response = await axios.get(apiUrl);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const DonateLandingPicture = LandingPagesPicture.find(
    (page) => page.id === "6"
  );

  return (
    <div className="fullpageStyle">
      <div className="">
        {data.length >= 6 && data[5].image !== "N" ? (
          <div className="">
            <Header />
            <div className="FoundationHeaderback">
              <img
                className="FullWindowImage"
                src={`${process.env.REACT_APP_API_URL}${data[5].image}`}
                alt="FoundationLanding-page"
              />
            </div>
            <div className="container">
              <ContactUsHeader />
            </div>
          </div>
        ) : (
          <div className="">
            <Header />
            <div className="FoundationHeaderback">
              <img
                className="FullWindowImage"
                src={DonateLandingPicture.img}
                alt={DonateLandingPicture.title}
              />
            </div>
            <div className="container">
              <ContactUsHeader />
            </div>
          </div>
        )}

        <div className="grid-horizontal topspace container verticalcenter">
          <BankCards />
          <MpgsCommingsoon />
          {/* <Mpgs /> */}
        </div>

        <div>
          <div id="embed-target" className="mpgsCard"></div>
        </div>

        <div className="footerDIV">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
