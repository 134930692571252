// useMessage.js
import { useState } from "react";

const useMessage = () => {
  const [messages, addMessages] = useState([]);

  const addMessage = (text, type) => {
    const id = Date.now();
    addMessages((prev) => [...prev, { id, text, type }]);
  };

  const removeMessage = (id) => {
    addMessages((prev) => prev.filter((message) => message.id !== id));
  };

  return { messages, addMessage, removeMessage };
};

export default useMessage;
