import React, { useState, useEffect } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";
import "./AdminService.css";

const UpdateContent = () => {
  const { messages, addMessage, removeMessage } = useMessage();
  const [toggleState, setToggleState] = useState(0);
  const [data, setData] = useState([]);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [selectedRecordImage, setSelectedRecordImage] = useState("");
  const [selectedRecordContent, setSelectedRecordContent] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 5;

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = data.slice(indexOfFirstImage, indexOfLastImage);

  const totalPages = Math.ceil(data.length / imagesPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    api
      .get("api/getcampaignsContent")
      .then((response) => {
        setData(
          response.data.map((item) => ({
            id: item.id,
            image: item.image,
            content: item.content,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleItemClick = (recordId, recordImage, recordContent) => {
    setSelectedRecordId(recordId);
    setSelectedRecordImage(recordImage);
    setSelectedRecordContent(recordContent);
    setToggleState(1); // Show the modal when an item is clicked
  };

  const updateRecord = async () => {
    try {
      const formData = new FormData();
      formData.append("image", selectedRecordImage);
      formData.append("content", selectedRecordContent);
      if (formData.image || formData.content) {
        await api.post(
          `api/updateourcampaignsContent/${selectedRecordId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        addMessage("Record updated successfully", "success");
        setToggleState(0); // Close the modal after updating
      } else {
        addMessage("Fields are Empty", "error");
      }

      // Update the local data to reflect the changes
      setData(
        data.map((record) =>
          record.id === selectedRecordId
            ? {
                ...record,
                image: selectedRecordImage,
                content: selectedRecordContent,
              }
            : record
        )
      );
    } catch (error) {
      console.error("Error updating record:", error);
      addMessage(`Failed to update record ${error}`, "error");
    }
  };

  const handleDelete = async (id) => {
    // Display error message if the user tries to delete record id 1, 2, or 3
    if (id === 1 || id === 2 || id === 3) {
      addMessage("Cannot delete records, only update is allowed", "error");
      return;
    }
    try {
      await api.delete(`api/delete-campaignsContent-image/${id}`);
      setData(data.filter((record) => record.id !== id));
      addMessage("Record deleted successfully", "success");
    } catch (error) {
      addMessage(`Failed to delete record ${error}`, "error");
    }
  };

  return (
    <div className="table_container admin-uploaded-img">
      <div className="tbl__container">
        <p className="tbl__container-admin">Our Campaign Records</p>

        <table className="Admin-tbl admin-uploaded-img-Admin-tbl">
          <thead>
            <tr>
              <th>Image</th>
              <th>Content</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {currentImages.map((record, i) => (
              <tr key={i}>
                <td data-table="user.username">
                  <img
                    src={`${process.env.REACT_APP_API_URL}${record.image}`}
                    alt={`service${record.id}`}
                  />
                </td>

                <td data-table="user.Img.content">{record.content}</td>

                <td data-table="user.email">
                  <button
                    className="Admin-Edit-button"
                    onClick={() =>
                      handleItemClick(record.id, record.image, record.content)
                    }
                  >
                    <svg
                      className="Admin-Edit-button-svg-icon"
                      fill="none"
                      height="20"
                      viewBox="0 0 24 24"
                      width="20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g stroke="red" strokeLinecap="round" strokeWidth="2">
                        <path d="m20 20h-16"></path>
                        <path
                          clipRule="evenodd"
                          d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                          fillRule="evenodd"
                        ></path>
                      </g>
                    </svg>
                    <span className="Admin-Edit-button-lable">Edit</span>
                  </button>

                  {toggleState === 1 && selectedRecordId === record.id && (
                    <div
                      className="services__modal active-modal"
                      style={{
                        position: "absolute",
                        top: "5vh",
                        left: "25vw",
                        zIndex: 9,
                        boxShadow: "0 1px 10px 0 black",
                      }}
                    >
                      <div className="services__modal-content update-Record-container">
                        <strong
                          onClick={() => setToggleState(0)}
                          className="services__modal-close update-Record"
                        >
                          X
                        </strong>

                        <div className="infos update-Record-info">
                          <form
                            className="Admin-form update-Record-Admin-form"
                            style={{ height: "fit-content" }}
                          >
                            <div>
                              <h2 className="tit__1 update-Record-info-h2">
                                Update The Campaigns
                              </h2>
                              <br />
                              <div className="flex update-Record-form">
                                <label>
                                  <input
                                    className="input"
                                    type="file"
                                    name="image"
                                    onChange={(e) =>
                                      setSelectedRecordImage(e.target.files[0])
                                    }
                                  />
                                </label>
                              </div>
                              <br />

                              <div className="Admininput-wrapper update-Record-form-wrapper">
                                <textarea
                                  placeholder="Content..."
                                  name="text"
                                  className="Admininput update-Record-Admininput"
                                  value={selectedRecordContent}
                                  onChange={(e) =>
                                    setSelectedRecordContent(e.target.value)
                                  }
                                ></textarea>
                              </div>
                            </div>

                            <button
                              className="bookmarkBtn"
                              onClick={updateRecord}
                              type="button"
                            >
                              <span className="IconContainer">
                                <svg
                                  viewBox="0 0 384 512"
                                  height="0.9em"
                                  className="icon"
                                >
                                  <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
                                </svg>
                              </span>
                              <p className="text">Update</p>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  )}
                </td>

                <td data-table="user.id">
                  <button
                    className="bin-button"
                    onClick={() => handleDelete(record.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 39 7"
                      className="bin-top"
                    >
                      <line
                        strokeWidth="4"
                        stroke="white"
                        y2="5"
                        x2="39"
                        y1="5"
                      ></line>
                      <line
                        strokeWidth="3"
                        stroke="white"
                        y2="1.5"
                        x2="26.0357"
                        y1="1.5"
                        x1="12"
                      ></line>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 33 39"
                      className="bin-bottom"
                    >
                      <mask fill="white" id="path-1-inside-1_8_19">
                        <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"></path>
                      </mask>
                      <path
                        mask="url(#path-1-inside-1_8_19)"
                        fill="white"
                        d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                      ></path>
                      <path
                        strokeWidth="4"
                        stroke="white"
                        d="M12 6L12 29"
                      ></path>
                      <path strokeWidth="4" stroke="white" d="M21 6V29"></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 89 80"
                      className="garbage"
                    >
                      <path
                        fill="white"
                        d="M20.5 10.5L37.5 15.5L42.5 11.5L51.5 12.5L68.75 0L72 11.5L79.5 12.5H88.5L87 22L68.75 31.5L75.5066 25L86 26L87 35.5L77.5 48L70.5 49.5L80 50L77.5 71.5L63.5 58.5L53.5 68.5L65.5 70.5L45.5 73L35.5 79.5L28 67L16 63L12 51.5L0 48L16 25L22.5 17L20.5 10.5Z"
                      ></path>
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={currentPage === i + 1 ? "active" : ""}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </div>
  );
};

export default UpdateContent;
