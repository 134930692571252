import React, { useState } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";

const UpdateGetInTouchUs = () => {
  const [title, setTitle] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [phone, setPhone] = useState("");
  const [facebook, setFacebook] = useState("");
  const [thought, setThought] = useState("");
  const { messages, addMessage, removeMessage } = useMessage();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title.length >= 50) {
      return addMessage(
        "Content and title must be under 300 and 50 characters",
        "error"
      );
    }
    if (
      whatsapp.length >= 100 ||
      phone.length >= 100 ||
      facebook.length >= 100 ||
      thought.length >= 100
    ) {
      return addMessage(
        "This field must be under 300 and 50 characters",
        "error"
      );
    }

    try {
      await api.post("api/updategetintouchus", {
        title,
        whatsapp,
        phone,
        facebook,
        thought,
      });
      addMessage("Record updated successfully", "success");
    } catch (error) {
      console.error("Error updating record:", error);
      addMessage(`Failed to update record. ${error}`, "error");
    }
  };

  return (
    <form
      className="Admin-form"
      onSubmit={handleSubmit}
      style={{ height: "fit-content" }}
    >
      <h2 className="tit__1">Update Get In Touch Us Section</h2>
      <p className="Expensivemessage">
        <strong className="ExpensiveIcon">Note</strong> If you update these
        Field Empty,{" "}
        <u className="black">the default value will be displayed.</u>
      </p>
      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Section Title "
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />{" "}
        </label>
      </div>
      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Whatsapp number"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
          />{" "}
        </label>
      </div>
      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Phone number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />{" "}
        </label>
      </div>
      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Facebook Link"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />{" "}
        </label>
      </div>
      <div className="flex">
        <label>
          {" "}
          <input
            className="input"
            type="text"
            placeholder="Thought"
            value={thought}
            onChange={(e) => setThought(e.target.value)}
          />{" "}
        </label>
      </div>
      <button className="bookmarkBtn">
        <span className="IconContainer">
          {" "}
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            {" "}
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>{" "}
          </svg>
        </span>
        <p className="text">Update</p>
      </button>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </form>
  );
};

export default UpdateGetInTouchUs;
