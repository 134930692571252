import React, { useState } from "react";

import UploadSlideImageComponent from "./UploadSlideImage";
import DeleteSlideImageComponent from "./DeleteSlideImage";
import UpdateSliderimageComponent from "./UpdateSliderimage";
import Sidebar from "../../global/Sidebar";
import TopBar from "../../global/TopBar";
import Footer from "../../../components/footer/Footer";

const AdminSlider = () => {
  // State to track which form is selected
  const [selectedForm, setSelectedForm] = useState("UploadSlideImageComponent");

  // Handler for switching between forms
  const handleFormChange = (form) => {
    setSelectedForm(form);
  };
  return (
    <div className="userStatus">
      <div className="Adminheader">
        <TopBar />
      </div>

      <div className="sidebar_and_Page" style={{ height: "fit-content" }}>
        <div
          className="sidebar"
          style={{ backgroundColor: "#21232d", height: "100%" }}
        >
          <Sidebar />
        </div>

        <div className="userChoice">
          <div className="formNav">
            {/* Buttons for selecting the form */}
            <button
              className={
                selectedForm === "UploadSlideImageComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("UploadSlideImageComponent")}
            >
              Upload Partners Images
            </button>
            <button
              className={
                selectedForm === "UpdateSliderimageComponent" ? "active" : ""
              }
              onClick={() => handleFormChange("UpdateSliderimageComponent")}
            >
              Update Partners images
            </button>
          </div>
          <div className="adminpageS container">
            {selectedForm === "UploadSlideImageComponent" && (
              <div className="grid-horizontal">
                <UploadSlideImageComponent />
                <div className="gallaryComponent">
                  <h3 className="oneLineTitle">Our Partners</h3>
                  <DeleteSlideImageComponent />
                </div>
              </div>
            )}
            {selectedForm === "UpdateSliderimageComponent" && (
              <UpdateSliderimageComponent />
            )}
          </div>
        </div>
      </div>

      <div className="footerDIV AdminFooter">
        <Footer />
      </div>
    </div>
  );
};

export default AdminSlider;
