import React, { useState } from "react";

import axios from "axios";
import zxcvbn from "zxcvbn"; // Library for password strength estimation npm install zxcvbn

import useMessage from "../message/useMessage";
import MessageComponent from "../message/MessageComponent";
import "./CreateUser.css";

const Creatuser = () => {
  const { messages, addMessage, removeMessage } = useMessage();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);

  const createUser = async (e) => {
    e.preventDefault();

    try {
      // Check for empty fields
      if (!username || !password || !email || !firstname) {
        addMessage("All fields are required", "error");
        return;
      }
      // Check password length
      if (password.length < 6) {
        addMessage("Password must be at least 6 characters long", "error");
        return;
      }

      // Check password strength
      const passwordScore = zxcvbn(password).score;
      if (passwordScore < 3) {
        addMessage("Please use a stronger password", "error");
        return;
      }

      // If username and email are unique, proceed to create the user
      const formData = { username, password, email, firstname };
      // Create user
      try {
        // Validate form fields here (username, email, etc.)
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/Samusercreation`,
          formData
        );

        if (response.status === 201) {
          addMessage(response.data.message, "success");
        }
      } catch (error) {
        if (error.response) {
          console.log("error", error.response.data.message);
          // Handle errors from the server
          addMessage(error.response.data.message, "error");
        } else {
          // Handle network or unexpected errors
          addMessage("An unexpected error occurred", "error");
        }
      }
    } catch (error) {
      addMessage(`An error occurred ${error}`, "error");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const score = zxcvbn(e.target.value).score;
    setPasswordStrength(score);
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0:
      case 1:
        return "red";
      case 2:
        return "yellow";
      case 3:
      case 4:
        return "green";
      default:
        return "";
    }
  };

  return (
    <div className="user_account">
      <form className="Admin-form">
        <p className="tit__1">Create User</p>
        <p className="Expensivemessage">
          {" "}
          <strong className="ExpensiveIcon">* </strong> Maximum{" "}
          <u className="ExpensiveLine">four</u> users are allowed for creation
        </p>
        <div className="Admin-form-create-grid">
          <label>
            {" "}
            <input
              className="input"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoComplete="off"
            />{" "}
          </label>
          <label>
            {" "}
            <input
              className="input"
              type="text"
              placeholder="first name"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              autoComplete="off"
            />{" "}
          </label>
        </div>

        <label>
          {" "}
          <input
            className="input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
            style={{
              backgroundImage: `linear-gradient(to left, ${getPasswordStrengthColor()} -50%, transparent 60%)`,
            }}
            autoComplete="off"
          />{" "}
        </label>
        <label>
          {" "}
          <input
            className="input"
            type="email"
            placeholder="Email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />{" "}
        </label>

        <button className="Adminsubmit" onClick={createUser}>
          Create User
        </button>
        <MessageComponent messages={messages} removeMessage={removeMessage} />
      </form>
    </div>
  );
};

export default Creatuser;
