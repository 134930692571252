import React, { useState, useEffect } from "react";

import axios from "axios";

import { Link } from "react-router-dom";

function Social() {
  const [links, setlinks] = useState([]);

  useEffect(() => {
    fatchlinks();
  }, []);

  const fatchlinks = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/Foundationlinks`;
      const result = await axios.get(apiUrl);
      setlinks(result.data);
    } catch (err) {
      console.log("something wrong");
    }
  };
  return (
    <div className="home__social container">
      {/* fb */}
      {Array.isArray(links) && links.length > 0 && links[0].facebook === "N" ? (
        <Link
          to="https://www.facebook.com/profile.php?id=100090874201533&mibextid=ZbWKwL"
          className="home__social-icon"
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/Svg/facebook-round-color-icon.svg"
            }
            alt="Facebook"
            className="socialSvg twitterSvg"
          />
        </Link>
      ) : links.length > 0 ? (
        links.map((txt, i) => (
          <Link to={txt.facebook} className="home__social-icon">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/Svg/facebook-round-color-icon.svg"
              }
              alt="Facebook"
              className="socialSvg twitterSvg"
            />
          </Link>
        ))
      ) : (
        <Link
          to="https://www.facebook.com/profile.php?id=100090874201533&mibextid=ZbWKwL"
          className="home__social-icon"
        >
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/Svg/facebook-round-color-icon.svg"
            }
            alt="Facebook"
            className="socialSvg twitterSvg"
          />
        </Link>
      )}

      {/* tg */}
      {Array.isArray(links) && links.length > 0 && links[0].telegram === "N" ? (
        <Link
          to="https://t.me/samrawitfoundation"
          className="home__social-icon"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/Svg/telegram.svg"}
            alt="Telegram"
            className="socialSvg twitterSvg"
          />
        </Link>
      ) : links.length > 0 ? (
        links.map((txt, i) => (
          <Link to={txt.telegram} className="home__social-icon">
            <img
              src={process.env.PUBLIC_URL + "/assets/Svg/telegram.svg"}
              alt="Telegram"
              className="socialSvg twitterSvg"
            />
          </Link>
        ))
      ) : (
        <Link
          to="https://t.me/samrawitfoundation"
          className="home__social-icon"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/Svg/telegram.svg"}
            alt="Telegram"
            className="socialSvg twitterSvg"
          />
        </Link>
      )}

      {/* x */}
      {Array.isArray(links) && links.length > 0 && links[0].x === "N" ? (
        <Link
          to="https://x.com/samrawitfound?t=x4Hd9Ab0xXlOAU2KxPLYOA&s=35"
          className="home__social-icon"
          target="_blank"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/Svg/icons8-twitter-x-bg.svg"}
            alt="X"
            className="socialSvg twitterSvg"
          />
        </Link>
      ) : links.length > 0 ? (
        links.map((txt, i) => (
          <Link to={txt.x} className="home__social-icon" target="_blank">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/Svg/icons8-twitter-x-bg.svg"
              }
              alt="X"
              className="socialSvg twitterSvg"
            />
          </Link>
        ))
      ) : (
        <Link
          to="https://x.com/samrawitfound?t=x4Hd9Ab0xXlOAU2KxPLYOA&s=35"
          className="home__social-icon"
          target="_blank"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/Svg/icons8-twitter-x-bg.svg"}
            alt="X"
            className="socialSvg twitterSvg"
          />
        </Link>
      )}

      {/* tiktok */}
      {Array.isArray(links) && links.length > 0 && links[0].x === "N" ? (
        <Link
          to="https://www.tiktok.com/@samrawitfoundation?_t=8hylLGsv1B0&_r=1"
          className="home__social-icon"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/Svg/tiktok-color-icon.svg"}
            alt="TikTok"
            className="socialSvg whatsappSvg"
          />
        </Link>
      ) : links.length > 0 ? (
        links.map((txt, i) => (
          <Link to={txt.tiktok} className="home__social-icon">
            <img
              src={process.env.PUBLIC_URL + "/assets/Svg/tiktok-color-icon.svg"}
              alt="TikTok"
              className="socialSvg whatsappSvg"
            />
          </Link>
        ))
      ) : (
        <Link
          to="https://www.tiktok.com/@samrawitfoundation?_t=8hylLGsv1B0&_r=1"
          className="home__social-icon"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/Svg/tiktok-color-icon.svg"}
            alt="TikTok"
            className="socialSvg whatsappSvg"
          />
        </Link>
      )}
    </div>
  );
}

export default Social;
