import React, { useState, useEffect } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";
import axios from "axios";
import HelperCard from "../HelperCard/HelperCard";

const Createhomepicturelandingtxt = () => {
  const { messages, addMessage, removeMessage } = useMessage();
  const [content, setContent] = useState("");
  const [existingRecords, setExistingRecords] = useState(0);

  useEffect(() => {
    const getRecords = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/homepicturelandingtxt`
        );
        setExistingRecords(response.data.length);
      } catch (error) {
        console.error("Error fetching existing records:", error);
      }
    };
    getRecords();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (existingRecords >= 2) {
      addMessage("Only 2 records allowed.", "error");
      return;
    }

    try {
      await api.post("api/create-homepicturelandingtxt-Record", {
        content: content,
      });
      addMessage("Record created successfully", "success");
    } catch (error) {
      console.error("Error creating record:", error);
      addMessage("An error occurred. Please try again.", "error");
    }
  };

  return (
    <>
      {existingRecords >= 2 ? (
        <HelperCard
          title="Homepage Paragraph"
          description="You have already created two Home Page paragraphs. While we cannot add additional paragraphs, you are welcome to update the existing at the next tab here."
          others={
            <button className="HelperCard-accept">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-arrow-big-right-dash"
              >
                <path d="M5 9v6" />
                <path d="M9 9h3V5l7 7-7 7v-4H9V9z" />
              </svg>
            </button>
          }
        />
      ) : (
        <form className="Admin-form" onSubmit={handleSubmit}>
          <h2 className="tit__1">Create Homepage Paragraph</h2>
          <p className="Expensivemessage ExpensiveIcon">
            <strong className="ExpensiveIcon"> + </strong>This is used to create
            a homepage paragraph that will be placed on the landing Page Picture
          </p>
          <p className="Expensivemessage">
            <strong className="ExpensiveIcon"> + </strong> You can only create{" "}
            <u className="black"> two</u> records
          </p>
          <br />
          <div className="flex">
            <div className="Admininput-wrapper">
              <textarea
                placeholder="Create A home Page introduction's"
                name="text"
                className="Admininput"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              ></textarea>
            </div>
          </div>
          <button className="bookmarkBtn">
            <span className="IconContainer">
              <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
              </svg>
            </span>
            <p className="text">Submit</p>
          </button>
          <MessageComponent messages={messages} removeMessage={removeMessage} />
        </form>
      )}
    </>
  );
};

export default Createhomepicturelandingtxt;
