import React, { useState, useEffect } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";

function Levelofauthoritytitles() {
  const [recordId, setRecordId] = useState("");
  const [category, setCategory] = useState("");
  const { messages, addMessage, removeMessage } = useMessage();
  const [recordIds, setRecordIds] = useState([]);

  useEffect(() => {
    const fetchRecordIds = async () => {
      try {
        const response = await api.get("api/allProffesionsdata");
        setRecordIds(response.data);
      } catch (error) {
        console.error("Error fetching record IDs:", error);
        addMessage("Error fetching record", "error");
      }
    };

    fetchRecordIds();
  }, [addMessage]);

  const updateRecord = async () => {
    try {
      if (category.length >= 100) {
        return addMessage("Category must be under 100 characters", "error");
      }
      await api.post(`api/updateallProffesionsdata/${recordId}`, {
        category,
      });

      addMessage("Category updated successfully", "success");
    } catch (error) {
      console.error("Error updating record:", error);
      addMessage(`Failed to update Category ${error}`, "error");
    }
  };

  return (
    <form className="Admin-form" style={{ height: "fit-content" }}>
      <h2 className="tit__1">Update Members Hierarchy Titles</h2>

      <p className="Expensivemessage">
        <strong className="ExpensiveIcon">Note</strong> If you update this Field
        Empty, <u className="black">the default value will be displayed.</u>
      </p>
      <div className="flex">
        <label>
          <select
            className="input"
            value={recordId}
            onChange={(e) => setRecordId(e.target.value)}
          >
            <option value="">Select Hierarchy</option>
            {recordIds.map((record) => {
              const defaultValues = {
                1: "Meet Our Team",
                2: "Executive Board members",
                3: "Executive Management Members",
                4: "Our Good Will Ambassador",
              };

              return (
                <option key={record.id} value={record.id}>
                  {record.category === "N" && defaultValues[record.id]
                    ? defaultValues[record.id]
                    : record.category}
                </option>
              );
            })}
          </select>
        </label>
      </div>
      <div className="flex">
        <label>
          <input
            className="input"
            type="text"
            placeholder="new hierarchy title"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />{" "}
        </label>
      </div>
      <button
        className="bookmarkBtn"
        onClick={updateRecord}
        disabled={!recordId}
      >
        <span className="IconContainer">
          <svg viewBox="0 0 384 512" height="0.9em" className="icon">
            <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
          </svg>
        </span>
        <p className="text">Update</p>
      </button>
      <MessageComponent messages={messages} removeMessage={removeMessage} />
    </form>
  );
}

export default Levelofauthoritytitles;
