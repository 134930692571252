import React, { useState } from "react";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import MessageComponent from "../adminComponents/message/MessageComponent";
import useMessage from "../adminComponents/message/useMessage";

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState("");
  const { messages, addMessage, removeMessage } = useMessage();

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/forgotPass`,
        { email }
      );
      console.log("Response from backend:", response.data);
      addMessage("Email Sent successfully", "success");
      navigate("/reset-pass");
    } catch (error) {
      addMessage(`Email not sent${error}`, "error");
    }
  };

  return (
    <div className="Resetcard">
      <span className="Resetcard__title">Reset User Account</span>
      <p className="Resetcard__content red">
        Please enter your verified email address registered with us to reset
        your account.
      </p>

      <div className="Resetcard__form">
        <input
          className="Resetcard__formInput"
          placeholder="Your Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{
            fontFamily:
              'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
          }}
        />
        <button className="Resetbutton" onClick={handleSubmit}>
          {" "}
          <span className="transition"></span>{" "}
          <span className="gradient"></span>{" "}
          <span className="label">Reset Account</span>
        </button>
        <MessageComponent messages={messages} removeMessage={removeMessage} />
        <Link to="/login" className="cssbuttons-io-button">
          {" "}
          Go Back
          <div className="icon">
            <svg
              height="24"
              width="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              style={{ transform: "rotate(180deg)" }}
            >
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
