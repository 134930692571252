import React, { useEffect } from "react";
import "./MessageStyle.css";

const MessagePopup = ({ messages, removeMessage }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (messages.length > 0) {
        removeMessage(messages[0].id); // Remove the first message after 5 seconds
      }
    }, 3000); // 3 seconds

    return () => clearTimeout(timer); // Clean up the timer
  }, [messages, removeMessage]);

  return (
    <div className="msg-popup-container">
      {messages.map((message) => (
        <div key={message.id} className={`msg-popup-message ${message.type}`}>
          {message.type === "error" ? (
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#ffffff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-circle-alert error__icon"
              >
                <circle cx="12" cy="12" r="10" />
                <line x1="12" x2="12" y1="8" y2="12" />
                <line x1="12" x2="12.01" y1="16" y2="16" />
              </svg>
              &nbsp; &nbsp; &nbsp;
              {message.text}
            </div>
          ) : message.type === "success" ? (
            <div>
              <svg
                className="success__icon"
                fill="none"
                height="24"
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="m12 1c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm4.768 9.14c.0878-.1004.1546-.21726.1966-.34383.0419-.12657.0581-.26026.0477-.39319-.0105-.13293-.0475-.26242-.1087-.38085-.0613-.11844-.1456-.22342-.2481-.30879-.1024-.08536-.2209-.14938-.3484-.18828s-.2616-.0519-.3942-.03823c-.1327.01366-.2612.05372-.3782.1178-.1169.06409-.2198.15091-.3027.25537l-4.3 5.159-2.225-2.226c-.1886-.1822-.4412-.283-.7034-.2807s-.51301.1075-.69842.2929-.29058.4362-.29285.6984c-.00228.2622.09851.5148.28067.7034l3 3c.0983.0982.2159.1748.3454.2251.1295.0502.2681.0729.4069.0665.1387-.0063.2747-.0414.3991-.1032.1244-.0617.2347-.1487.3236-.2554z"
                  fill="#393a37"
                  fillRule="evenodd"
                ></path>
              </svg>
              &nbsp; &nbsp; &nbsp;
              {message.text}
            </div>
          ) : (
            message.type === "warning" && (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-message-square-warning"
                >
                  <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
                  <path d="M12 7v2" />
                  <path d="M12 13h.01" />
                </svg>
                &nbsp; &nbsp; &nbsp;
                {message.text}
              </div>
            )
          )}
        </div>
      ))}
    </div>
  );
};

export default MessagePopup;
