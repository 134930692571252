import React, { useEffect, useState } from "react";

import axios from "axios";

import Services from "../../components/Services/Services";
import ServicesHeader from "../../components/Services/ServicesHeader";
import "./OurServices.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import LandingPagesPicture from "../../Data/LandingPages";

const OurServices = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}api/LandingPagePicture`;
        const response = await axios.get(apiUrl);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const ServicesLandingPicture = LandingPagesPicture.find(
    (page) => page.id === "3"
  );

  return (
    <div className="fullpageStyle">
      <div className="">
        {data.length >= 5 && data[4].image !== "N" ? (
          <div className="">
            <Header />
            <div className="FoundationHeaderback">
              <img
                className="FullWindowImage"
                src={`${process.env.REACT_APP_API_URL}${data[4].image}`}
                alt="ServicesLanding-page"
              />
            </div>
            <div className="container">
              <ServicesHeader />
            </div>
          </div>
        ) : (
          <div className="">
            <Header />
            <div className="FoundationHeaderback">
              <img
                className="FullWindowImage"
                src={ServicesLandingPicture.img}
                alt={ServicesLandingPicture.title}
              />
            </div>
            <div className="container">
              <ServicesHeader />
            </div>
          </div>
        )}

        <div>
          <Services />
        </div>
        <div className="footerDIV">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default OurServices;
