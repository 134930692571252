import React, { useState, useEffect } from "react";

import axios from "axios";

import "./Map.css";

function Map() {
  const [data, setdata] = useState([]);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}api/maplocationService`;
    axios
      .get(apiUrl)
      .then((result) => {
        setdata(
          result.data.map((item) => ({
            id: item.id,
            title: item.title,
            degree: item.degree,
            position: item.position,
          }))
        );
      })

      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <div className="locationService">
      <div className="home__contents">
        {Array.isArray(data) && data.length > 0 && data[0].title === "N" ? (
          <h1 className="h1">Head Quarter Address</h1>
        ) : data.length > 0 ? (
          data.map((txt, i) => (
            <h1 className="h1" key={i}>
              {txt.title}
            </h1>
          ))
        ) : (
          <h1 className="h1">Head Quarter Address</h1>
        )}
      </div>

      <div className="map-section">
        <div className="contact__cards">
          <i className="uil uil-location-pin-alt contact__card-icon map"></i>
          <h3 className="contact__card-title">Headquarter</h3>
          <br />

          {Array.isArray(data) &&
          data.length > 0 &&
          data[0].position === "N" &&
          data[0].degree === "N" ? (
            <>
              <span className="contact__card-data">
                8°57'11.9"N 38°47'13.3"E
              </span>
              <br />
              <span className="contact__card-data">
                Bole Bulbula, St. Gebriel building 2nd floor
              </span>
            </>
          ) : data.length > 0 ? (
            data.map((txt, i) => (
              <div key={i}>
                <span className="contact__card-data">{txt.degree}</span>
                <br />
                <span className="contact__card-data">{txt.position}</span>
              </div>
            ))
          ) : (
            <>
              <span className="contact__card-data">
                8°57'11.9"N 38°47'13.3"E
              </span>
              <br />
              <span className="contact__card-data">
                Bole Bulbula, St. Gebriel building 2nd floor
              </span>
            </>
          )}

          <span className="contact__card-data">Addis Ababa, Ethiopia</span>
        </div>

        <div className="gmap-frame">
          <iframe
            title="Samrawit Foundation"
            className="iframe_map dark-mode-map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.205278368186!2d38.78432620000001!3d8.953223400000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b855fcf95a1e7%3A0x80a2ef4ca71050e6!2sSamrawit%20Foundation!5e0!3m2!1sen!2set!4v1711353633581!5m2!1sen!2set"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Map;
