import React, { useState, useEffect } from "react";

import { api } from "../../global/Auth/api";

import MessageComponent from "../message/MessageComponent";
import useMessage from "../message/useMessage";

const UploadLandingPagePictures = ({ dataLength }) => {
  const { messages, addMessage, removeMessage } = useMessage();
  const [file, setFile] = useState(null);
  const [canUpload, setCanUpload] = useState(dataLength < 6); // Set based on prop

  useEffect(() => {
    setCanUpload(dataLength < 6); // Update when dataLength changes
  }, [dataLength]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const isValidImage = reader.result.startsWith("data:image");
      if (isValidImage) {
        setFile(file);
        addMessage(null); // Reset any previous error message
      } else {
        setFile(null);
        addMessage("Please select a valid image file", "error");
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Check if file is present
    if (file) {
      formData.append("image", file);

      try {
        await api.post("api/upload-LandingPage-picture", formData);
        addMessage("File uploaded successfully", "success");
        // Handle successful upload and reload logic here
      } catch (error) {
        addMessage(`Error uploading file: ${error}`, "error");
      }
    } else {
      addMessage(`Image is not detected`, "error");
    }
  };

  return (
    <>
      {/* Only show the title and form if less than 6 pictures are uploaded */}
      {canUpload && (
        <>
          <h2 className="tit__1">Create Landing Page Pictures</h2>
          <form
            className="Admin-form"
            onSubmit={handleSubmit}
            style={{ height: "fit-content" }}
          >
            <p className="Expensivemessage ExpensiveIcon">
              <strong className="ExpensiveIcon"> + </strong>Landing Page Image
              Uploader.
            </p>

            <div className="Adminflex">
              <label>
                <input
                  className="input"
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                />
              </label>
            </div>

            <button className="bookmarkBtn buttonUp" type="submit">
              <span className="IconContainer">
                <svg viewBox="0 0 384 512" height="0.9em" className="icon">
                  <path d="M0 48V487.7C0 501.1 10.9 512 24.3 512c5 0 9.9-1.5 14-4.4L192 400 345.7 507.6c4.1 2.9 9 4.4 14 4.4c13.4 0 24.3-10.9 24.3-24.3V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48z"></path>
                </svg>
              </span>
              <p className="text">Upload</p>
            </button>

            <MessageComponent
              messages={messages}
              removeMessage={removeMessage}
            />
          </form>
        </>
      )}
      {/* No message or UI is shown if the limit is reached */}
    </>
  );
};

export default UploadLandingPagePictures;
